import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    all_assets:[],
    all_files: [],
    selected_asset_files:[],
    is_loading:false,
    is_brand_name_available: false,
    brand_name_popup: false,
    brand_box_clicked: false,
    upload_files_popup:false,
    delete_asset_loading:false
};

export const assetSlice = createSlice({
  name: "assets",
  initialState: initialValue,
  reducers: {
    setAssetState: (state, action) => {
      assignValueInState(action.payload, state);
    },
    resetAssetState: (state) => {
      assignValueInState(initialValue, state);
    },
  },
});

const assignValueInState = (obj, state) => {
  for (const key in obj) {
    state[key] = obj[key];
  }
};

export const { setAssetState, resetAssetState } = assetSlice.actions;
export default assetSlice.reducer;