import React from 'react'
import AuthLayout from '../layouts/AuthLayout'
import { AppBar, Box, IconButton, Typography,Input, Checkbox, Backdrop} from '@mui/material'
import Header from './components/Header'
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from '../../components/CustomButton';
import { useState } from 'react';
import CustomSortOptions from '../events/components/CustomSortOptions';
import { downloadS3File, fileFormat, removeExtension, sizeConverter, timeStampToNumberedDateString, timeStampToString } from '../../utils/commonServices';
import { useDispatch, useSelector } from 'react-redux';
import UserMediaFilesTable from './components/UserMediaFilesTable';
import {  mediaFilesSortOptions } from '../../constants/staticData';
import { exampleFiles } from '../../constants/staticData';
import useAllMediaFiles from '../events/hooks/useAllMediaFiles';
import useSort from '../events/hooks/useSort';
import { useEffect } from 'react';
import useCreateZippedMedia from './hooks/useCreateZippedMedia';
import useDownloadZippedMedia from './hooks/useDownloadZippedMedia';
import { useRef } from 'react';
import useZippedMedia from './hooks/useZippedMedia';
import DownloadConfirmationPopup from './components/downloadConfirmationPopup';
import { setAllMediaFilesArray } from '../../redux/mediaFilesSlice';
import { getMediaFileProjects } from '../../commonactions/actions';
import { useNavigate } from 'react-router';

 const UserMediaFiles = () => {

    const [selectedSortbyValue, setSelectedSortbyValue] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const mediaFilesReducer = useSelector((state) => state.mediaFilesReducer);
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    
    const [mediaFiles, setMediaFiles] = useState([]);
    const [searchedArray, setSearchedArray] = useState(mediaFiles)
    const [sortedFiles] = useSort(searchedArray, selectedSortbyValue)
    // const [refetchAllMediaFiles, allMediaFilesStatus] = useAllMediaFiles(true)

    // const [createZippedMediaMutate, createZippedMediaStatus , downloadZippedMediaID] = useCreateZippedMedia()
    const [enableDownload, setEnableDownload] = useState(false)
    // const [zipUrl, downloadLink, downloadZippedMediaStatus,setDownloadZippedMediaStatus ] = useDownloadZippedMedia(downloadZippedMediaID, enableDownload, setEnableDownload)

    const [createZippedMediaMutate, createZippedMediaStatus, downloadZippedMediaStatus, downloadLink] = useZippedMedia('create-zipped-media')
    // const [downloadLink ,downloadZippedMediaStatus] = useZippedMedia("download-zipped-media")


useEffect(()=>{
  getMediaFileProjects(dispatch, navigate, commonReducer)
},[])
   
    //({downloadZippedMediaStatus})
   // console.log({createZippedMediaStatus})


// useEffect(()=>{
//   if(downloadZippedMediaStatus == 'loading'){
//     dispatch(setAllMediaFilesArray({download_confirmation_popup:false})) 
//   }
//   if(createZippedMediaStatus == 'success'){
//     refetchAllMediaFiles()
//   }
// },[downloadZippedMediaStatus, createZippedMediaStatus])

    useEffect(()=>{
      if(createZippedMediaStatus == 'success'){
        setMediaFiles(mediaFilesReducer.my_files_project_media_files?.map(file => ({...file, checked:false})))
        // setMediaFiles(mediaFilesReducer.all_media_files?.map(file => ({...file, checked:false})))
      }
    },[createZippedMediaStatus])

  

    useEffect(()=>{
        // setMediaFiles(mediaFilesReducer.all_media_files?.map(file => ({...file, checked:false})))
        setMediaFiles(mediaFilesReducer.my_files_project_media_files?.map(file => ({...file, checked:false})))
    },[mediaFilesReducer.my_files_project_media_files])

    useEffect(()=>{
        setSearchedArray(mediaFiles)
    },[mediaFiles])

   
    const downloadSelectedFiles =async ()=>{
        const selectedFiles = mediaFiles?.filter(file => file.checked)?.map(file =>  file.id);
        await createZippedMediaMutate({ids: JSON.stringify(selectedFiles)})
        //console.log('download selected files', selectedFiles)
    }

  
   

    // const selectAllFavouritesHandler =()=>{
    //     // const favFiles = fileState?.filter(file => file.favourite )
    
    //     const favFiles = mediaFiles.filter((file) => file.favourite);
    //     const updatedFiles = mediaFiles.map((file) => ({...file, checked: favFiles.includes(file)}));
    //     setMediaFiles(updatedFiles);
    
    //     setSelectAll(favFiles.length == mediaFiles.length);
    //   }

      const handleFileCheckboxChange = (index) => {
        const updatedFiles = [...sortedFiles];
        updatedFiles[index].checked = !updatedFiles[index].checked;
        setSelectAll(false)
        setSearchedArray(updatedFiles)
        // setMediaFiles(updatedFiles);
      };

      const handleSelectAllChange = (event) => {
        const checked = event.target.checked;
        const updatedFiles = mediaFiles.map((file) => ({ ...file, checked: checked }));
        setMediaFiles(updatedFiles);
        setSelectAll(checked);
      };


      const handleSearch = (e) => {
        const searchedInput = e.target.value.toLowerCase();
        setSearchTerm(searchedInput);

        const updatedFiles = [...mediaFiles];
    
        const results = updatedFiles?.filter(item =>
          item.name?.toLowerCase().includes(searchedInput) ||
          item.event_name?.toLowerCase().includes(searchedInput)||
          item.uploaded_by?.toLowerCase().includes(searchedInput)||
          timeStampToString(item?.upload_time)?.toLowerCase().includes(searchedInput)
        );
        setSearchedArray(results);
      };

     


  return (
    <AuthLayout sx={{ padding: '2.1% 3.4% 2.1% 3.4%'}}>
        <Header/>
        <Box px={3} pr={6} className='flex col' mt={4} sx={{minWidth:'800px'}}>
            <Box className='flex j-between' mb={3}>
                <Box className='flex a-center' gap={4}>
                    <Typography sx={{color:'#000000', fontFamily:'Rubik', fontWeight:600, fontSize:{xs:'16px',md:'18px',lg:'20px',xl:'30px'}}}>Your Files</Typography>
                    <Box id="search" >
                        <Input
                        value={searchTerm}
                        onChange={handleSearch}
                        inputProps={{ maxLength: 100 }}
                        sx={{
                          //  minWidth: '160px',
                            borderBottom: 2,
                            borderColor: "#333333",
                            input: {
                                fontSize: { xs: '12px', md: '13px', lg: '14px', xl: '20px' },
                                fontWeight: 400,
                                color: "#000000",
                            },
                        }}
                        placeholder="Search"
                        startAdornment={
                            <IconButton sx={{paddingBottom:1, fontSize: {xs:'12px',md:'14px',lg:'17px',xl:'24px'}, '& .MuiSvgIcon-root':{fontSize:{xs:'12px',md:'14px',lg:'17px',xl:'24px'}}, paddingRight:2}} >
                                <SearchIcon style={{ color: "#000000" }} />
                            </IconButton>
                        }
                        />
                    </Box>
                </Box>
                <Box className='flex a-center' gap={2}>
                    <CustomButton 
                    btnText='Download Selected'
                    onClick={downloadSelectedFiles}
                    disabled={ sortedFiles?.filter(file => file.checked == true).length == 0 || createZippedMediaStatus == 'loading' }
                    type='button'
                    color='secondary'
                    variant='contained'
                    style={{
                    borderRadius: '5px',
                    border: "none",
                    textTransform:'none',
                    // backgroundColor:theme.palette.secondary.main
                    }}
                    sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'20px',md:'22px',lg:'24px',xl:"32px"}, width:{xs:'110px',sm:'130px',md:'150px',lg:'160px',xl:'195px'}, fontFamily:'Rubik'}}
                    />
           
                    <CustomSortOptions
                    sortByOptions={mediaFilesSortOptions}
                    selectedSortbyValue={selectedSortbyValue}
                    setSelectedSortbyValue={setSelectedSortbyValue}
                          // onChange={(newValue) => console.log(newValue)}
                    />
                </Box>
            </Box>
            {/* {(allMediaFilesLoading == true) && <Typography variant='h3'>Loading...</Typography>} */}
            <UserMediaFilesTable 
                // files={searchedArray}
                // isLoading={allMediaFilesStatus}
                files={sortedFiles}
                handleSelectAllChange={handleSelectAllChange}
                handleFileCheckboxChange={handleFileCheckboxChange}
                selectAll={selectAll}
            />
     
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
          open={createZippedMediaStatus == 'loading' }
        >
          <Typography>Please wait while we prepare your files for download...</Typography>
        </Backdrop>

        {mediaFilesReducer.download_confirmation_popup == true && <DownloadConfirmationPopup url={downloadLink}/>}
    </AuthLayout>
  )
}

export default UserMediaFiles