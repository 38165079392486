
import luciLogo from "../../assets/luciLogo2.png"
import poweredBy from "../../assets/poweredBy.svg"
import phoneIcon from "../../assets/guestIcon.png"
import "@fontsource/rubik";
import { Box, Card, CardContent, CardActions, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import CustomDropBox from "../../components/CustomUploadZone";
import Bottleneck from "bottleneck";
import { useLocation } from "react-router-dom";
import { isEventDate, timeStampToDayString } from '../../utils/commonServices';
import useSequentialVideoUpload from "../../hooks/useSequentialVideoUpload";
import CustomVideoTable from "../../components/CustomVideoTable";
import PhoneGuestPage from "./components/PhoneGuestPage";
import { useDispatch, useSelector } from "react-redux";
import useEventDetails from "./hooks/useEventDetails";
import useConsentDetail from "../events/hooks/useConsentDetail";
import { guestEventDetails } from "../../services/api";
import { updateState } from "../../redux/commonSlice";
import CustomGuestVideoTable from "../../components/CustomGuestVideoTable";
import CustomSnackbar from "../../components/CustomSnackbar";
import useCustomTheme from "../../theme/useCustomTheme";
import GuestImage from "./assets/GuestImage";

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        padding: '4.5% 4.9% 6% 5.9%',
        height: '100vh',
        width: '100%',
        // backgroundImage: 'linear-gradient(140deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
        backgroundImage: `linear-gradient(110deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670, ${theme.palette.secondary.main}20 , ${theme.palette.secondary.main}50 80%,  ${theme.palette.primary.main}60 , ${theme.palette.primary.main}60 )`,
        display: 'flex',
        flexDirection: 'column'
    },
    leftContainer: {
        width: '21.1%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '41px',
        marginTop: '74px',
        paddingLeft: '2.24%',
    },
    rightContainer: {
        // width:'78.9%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '4.9%',
        marginBottom: '51px',
        marginLeft: '1.7%',
    },
    fileBox: {
        width: '100%',
        display: 'flex',
        paddingLeft: '1.6%'
    }
}));

const GuestUserPage = (props) => {

    const classes = useStyles();
    const [theme ] = useCustomTheme()
    // const [pixelRatio] = useDevicePixelRatio()
    //  useEffect(()=> {
    //     console.log("pixel", pixelRatio)
    //  }, [pixelRatio])

    const limiter = new Bottleneck({
        maxConcurrent: 1,
        minTime: 200
    });

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const code = query.get('c')

    // const id = query.get('e')
    // const name = query.get('n');
    // const startDate = query.get('s')
    // const endDate = query.get('end')
    // const userName = query.get('u')

    const { videoTitle, videoSrc, } = props
    const [openDrawer, setOpenDrawer] = useState(false);
    const [copyButtonText, setCopyButtonText] = useState("COPY LINK");

    const snackbarProps = useSelector((state) => state.messageReducer);
    const commonReducer = useSelector(state => state.root.commonReducer)
    const [eventData, setEventData] = useState()
    const {getEventGuestPageDetails} = useEventDetails()
    const [getConsentDetails] = useConsentDetail();
    const [pageLoading, setPageLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(()=> {
        getEventGuestPageDetails(code, ()=> {
            if(commonReducer?.guest_collab_code != code) {
                dispatch(updateState({guest_collab_code: code}))
                // window.location.href = window.location.href;
            }
            setPageLoading(false)
        } )
    }, [code])

    // useEffect(()=> {
    //     getEventGuestPageDetails(code )
    // }, [code])

    useEffect(() => {
        const selectedEvent = commonReducer.guest_events?.find((event) => event.event_id == commonReducer.guest_event_detail.id)
        if (!selectedEvent) {
            dispatch(updateState({event_details: {...commonReducer.guest_event_detail, consent_accepted: false }}))
            setEventData({...commonReducer.guest_event_detail, consentStatus: false })
            getConsentDetails(commonReducer.guest_event_detail.id)
        }
        else if (Object.keys(selectedEvent)?.length > 0) {
            dispatch(updateState({event_details: {...commonReducer.guest_event_detail, consent_accepted: selectedEvent.consent_status  }}))
            setEventData({...commonReducer.guest_event_detail, consentStatus: selectedEvent.consent_status })
        }
    }, [commonReducer.guest_events, commonReducer.guest_event_detail])


    const { files, setFiles,
        progress, setProgress,
        uploadStatus, setUploadStatus,
        isLoading,
        requestController, setRequestController,
        stopUpload, stopList } = useSequentialVideoUpload(commonReducer.guest_event_detail.id, true)   // useSequentialVideoUpload(eventId: , isGuestUser) 

        const themeReducer = useSelector((state) => state.root.themeReducer);
        // const brandLogo = window.localStorage.getItem('brand_logo_url')
        const brandLogo = themeReducer?.brand_logo

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [isLoading])
    const alertUser = e => {
        if (isLoading) {
            e.preventDefault()
            e.returnValue = ''
        }

    }


    return ( !pageLoading ? <>
        <Box container className={classes.container} sx={{ display: { xs: 'none', md: 'flex' } }} >

            <Box sx={{width: '10%' }} >
                <img src={brandLogo !== null ? brandLogo : luciLogo}  style={{maxWidth:'90px', maxHeight:'45px'}} />
                {
                    brandLogo !== null && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
                }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: { md: '35px', lg: '44px', xl: '66px' } }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        height: { md: '17vh', lg: '19vh', xl: '22vh' },
                        minHeight: '120px',
                        paddingX: '4vw',
                        borderRadius: '10px',
                        boxShadow: "0px 5px 15px #00000029",
                        width: '70vw'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            height: '100%'
                        }} >
                            <Typography 
                                mb={{xs:'10px',sm:'10px',md:'9px',lg:'10px',xl:'16px'}}
                                sx={{
                                    fontSize: { xs: '18px', sm: '20px', md: '24px', lg: '27px', xl: '40px' },
                                    fontFamily: "Rubik",
                                    color: '#08231B',
                                    fontWeight: 600,
                                    textAlign: 'left',
                                    margin: 0,
                                }}
                            >
                                Let's Collab! 
                            </Typography>

                            <Typography sx={{
                                fontSize: { xs: '10px', sm: '10px', md: '11px', lg: '11px', xl: '16px' },
                                fontFamily: "Rubik",
                                fontWeight: 300,
                                textAlign: 'left',
                                margin: 0
                            }} >{`Upload your files until `} <Typography component='span' sx={{
                                    fontSize: { xs: '10px', sm: '10px', md: '11px', lg: '11px', xl: '16px' },
                                    fontFamily: "Rubik", fontWeight: 600
                                }}>{timeStampToDayString(parseInt(eventData?.editor_start_date))}</Typography>{` to be featured on this Lucivideo: `} <Typography component='span' sx={{
                                    fontSize: { xs: '10px', sm: '10px', md: '11px', lg: '11px', xl: '16px' },
                                    fontFamily: "Rubik", fontWeight: 600
                                }}>{eventData?.name}</Typography></Typography>
                        </Box>
                        <GuestImage sx={{width: '30%', height: '100%'}} />
                        {/* <img src={phoneIcon} height='80%' /> */}

                    </Box>
                    <Box sx={{ width: '70vw', marginTop: { md: '7vh', lg: '9vh', xl: '11vh' } }} >
                        <CustomGuestVideoTable
                            progress={progress}
                            stopUpload={stopUpload}
                            uploadStatus={uploadStatus}
                            files={files}
                        />
                    </Box>
                </Box>

                <Box sx={{ width: '16vw', marginLeft: '3vh' }} >
                    <CustomDropBox
                        isDisabled={!isEventDate(eventData?.start_date, eventData?.editor_start_date) || isLoading}
                        style={{ display: { xs: 'none', md: 'flex' } }}
                        height='70vh'
                        // width='16vw'
                        eventData={eventData}
                        files={files}
                        setFiles={setFiles}
                        setUploadStatus={setUploadStatus}
                        setProgress={setProgress}
                        setRequestController={setRequestController}

                    />
                </Box>

            </Box>
        </Box >
        <Box width='100%' height='100%' sx={{ display: { xs: 'flex', md: 'none' } }}>
            <PhoneGuestPage
                files={files}
                progress={progress}
                uploadStatus={uploadStatus}
                stopUpload={stopUpload}
                setFiles={setFiles}
                setUploadStatus={setUploadStatus}
                setProgress={setProgress}
                setRequestController={setRequestController}
                stopList={stopList}
                isLoading={isLoading}
                // id={eventData?.id}
                // name={eventData?.name}
                // startDate={eventData?.start_date}
                // endDate={eventData?.end_date}
                eventData={eventData}
            />
        </Box>
        {Object.keys(snackbarProps).length > 0 && (
          <CustomSnackbar
         
          />
        )}
    </> : null
    
    )
}

export default GuestUserPage