import { useMemo, useState } from "react";
import useEventRequest from "../../../hooks/useEventRequest";
import useEventList from "../../dashboard/hooks/useEventList"; 
import { useDispatch, useSelector } from "react-redux";
import useValidator from "../../../hooks/useValidator";
import useGenreList from "../../../hooks/dropdowns/useGenreList";
import { useEffect } from "react";
import { Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, TextField, Typography } from "@mui/material";
import CustomInput from "./CustomInput";
import { getErrorMsg } from "../../../utils/validator";
import { afterValidate, bytesToMegabytes, convertSecondsToMinutes, generateLighterContrastColor, getFileType, isEventPending, setInput, sizeConverter, timeStampToDateString, timeStampToDateTime, timeStampToDayString } from "../../../utils/commonServices";
import CustomAutocomplete from "./CustomAutoComplete";
import useVideoLengthList from "../hooks/useVideoLengthList";
import useEventTypeList from "../hooks/useEventTypeList";
import DateTimePicker from "../../../components/date-time/DateTimePicker";
import CheckIcon from "../../../components/icons/CheckIcon";
import { updateState } from "../../../redux/commonSlice";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import "@fontsource/rubik";
import { resetCreationState } from "../../../redux/eventCreationSlice";
import { Tooltip } from "@material-ui/core";
import CustomTooltip from "../../../components/CustomTooltip";
import CustomTextbox from "./CustomTextbox";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import { customTheme as theme } from "../../../theme/customTheme";
import { getContrastRatio } from '@mui/material/styles';
import useCustomTheme from "../../../theme/useCustomTheme";


const CreateEvent = (props)=> {

    const {submitRequest, requestStatus, } = props;
    // const [pageData, setPageData] = useState({
    //     name : "",
    //     start_date: "",
    //     end_date: "",
    //     editor_start_date:"",
    //     video_orientation: "landscape", // landscape | portrait
    //     // song:'',
    //     description: "",
    //     quality_options: [1],
    //     event_type_id:'',
    //     video_length_id:'',
    // });
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const [pageData, setPageData] = useState(createEventReducer.pageData)
    const [checked, setChecked] = useState(false)
    const [snackbarProps, setSnackbarProps] = useState({});
    const [enableEvents, setEnableEvents] = useState(false);
    // const [refetchEvents] = useEventList(enableEvents);
    const [createEvent, createEventStatus, eventId] = useEventRequest("create")
    const [nameTooltip, setNametooltip] = useState(false)
  
  const [pageIndex, setPageIndex] = useState(0);
  const commonReducer = useSelector((state) => state.root.commonReducer);
  const teamsReducer = useSelector((state) => state.root.teamsReducer);
  const dropdownReducer = useSelector((state) => state.root.dropdownReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch()
//   const { next, pageData, setPageData , requestStatus} = props;
  const [errorObjectArray, checkEmptyFields, checkValue] = useValidator(
    pageData,
    "create_event"
  );
  const [startDateValidationFlag, setStartDateValidationFlag] = useState(false);
  const [endDateValidationFlag, setEndDateValidationFlag] = useState(false);
  const [editorStartDateValidationFlag, setEditorStartDateValidationFlag] = useState(false)
  const [nameValidationFlag, setNameValidationFlag] = useState(false);
  const [songTypeValidationFlag, setSongTypeValidationFlag] = useState(false)

   const [videoLength, selectedVideoLength] = useVideoLengthList()
   const [videoLengthValidationFlag, setVideoLengthValidationFlag] = useState(false)
   const [eventTypeList, selectedEventType] = useEventTypeList()
   const [eventTypeValidationFlag, setEventTypeValidationFlag] = useState(false)
    
   const [projectStartDateTime, setProjectStartDateTime] = useState(createEventReducer.edit !== true ? new Date(): new Date(pageData?.start_date * 1000))
   const [editStartDateTime, setEditStartDateTime] = useState(createEventReducer.edit !== true ? new Date():new Date(pageData?.editor_start_date * 1000) )

   const [fileFormatError, setFileFormatError] = useState(false)
   const [notesSizeUploadError, setNotesSizeUploadError] = useState(false)
   const [notesErrorMessage, setNotesErrorMessage] = useState('')
   const [theme ] = useCustomTheme()

   const eventType = useMemo(()=>{
    const existingType = dropdownReducer?.event_type_old?.find(type=> (pageData?.event_type_id == type.id && type.id != 3 && type.id != 7))
    // const existingType = dropdownReducer?.event_type_old?.find(type=> (commonReducer?.event_details?.event_type?.id == type.id && type.id != 3 && type.id != 7))
    if(!!existingType) {
      return [...eventTypeList, existingType]
    } else {
      return eventTypeList
    }
   }, [ pageData?.event_type_id])
 



    const prevousButtonHandler = ()=> {
        pageIndex > 0 && setPageIndex((last) => last-1)
    }
    const nextButtonHandler = ()=> {
        pageIndex < 5 && setPageIndex((last) => last+1)
    }

    const finishedHandler = ()=> {
      if(checked) {
        validateInputs(true)
        //  console.log(pageData)
              afterValidate(requestCallback);
              
             // setPageData(initialData)
      }
       
       
        
    }
    const requestCallback= async ()=>{
       if(createEventReducer.edit == true && createEventReducer.event_id != null) {
        await submitRequest({id: createEventReducer.event_id, param: pageData})
       } else{
        await submitRequest({...pageData, team_id: teamsReducer?.selected_team ? teamsReducer?.selected_team?.id : ''});
       }
        
      // await refetchEvents()
    }

    const validateInputs = (flag) => {
        setNameValidationFlag(flag)
        setEventTypeValidationFlag(flag)
        setVideoLengthValidationFlag(flag)
        setStartDateValidationFlag(flag)
        setEndDateValidationFlag(flag)
        setEditorStartDateValidationFlag(flag)
       // setSongTypeValidationFlag(flag)
    }


  useEffect(()=> {
    const date = new Date()
     // refetchEvents();
      setPageData((last) => {
        return { ...last, time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone , start_date: createEventReducer.edit !== true ? Math.floor(Date.now()/1000) :   pageData?.start_date};
    });
  },[])

    useEffect(()=> {
        if( requestStatus === 'success' ) {
            //refetchEvents();
            dispatch(updateState({selected_event: commonReducer.events.find((e)=> e.id == commonReducer.production_event_id) }))
           
            dispatch(resetCreationState());
            navigate('/productionevent', {replace: true})
            // setPageIndex((last) => last+1);
        }
    },[requestStatus])


    // const nextClickHandler = (event) => {
    //     setNameValidationFlag(true);
    //     setStartDateValidationFlag(true);
    //     setEndDateValidationFlag(true);
    //     // checkEmptyFields(event);
    //     // afterValidate(() => next());
    //   };

    
      const selectLandscape = () => {
        setPageData((last) => {
            return { ...last, video_orientation: 'landscape' }
        })
    }
    
    const selectPortrait = () => {
        setPageData((last) => {
            return { ...last, video_orientation: "portrait" }
        })
    }

    const handleChange = (event)=>{
      setChecked(event.target.checked)
    }

    const uploadNotesHandler = (event, newValue) => {
      console.log(event.target.files[0])
      console.log('type', getFileType(event.target.files[0].name))
      if(!event.target.files[0]?.type?.includes('application') || (getFileType(event.target.files[0].name) === 'unknown')){
        setFileFormatError(true)
        setNotesErrorMessage('Please upload file in valid format. (pdf, doc, docx, rtf)')
      }else if(bytesToMegabytes(event.target.files[0].size) > 10){
        setFileFormatError(true)
        setNotesErrorMessage('File size should be less than 10 Mb')
      }else{
        setFileFormatError(false)
        setPageData((last) => {
          return { ...last, note: event.target.files[0]?.type?.includes('application') ? event.target.files[0] : null };
        });
      }
  }

    return <>
 
      <Box sx={{display:'flex', flexDirection:'column', width:'100%', height:'100%', }}>
           {/* /////////////////////////////////////////////////////////////////////////////////////////////////// */}


      <Box sx={{width:'100%', marginBottom:{xs:'7px',sm:'12px',md:'15px',lg:'20px',xl:'33px'}}} textAlign='left'>

     <CustomTooltip arrow  PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              }}  
               open={nameTooltip} onClose={()=>setNametooltip(false)} disableHoverListener title={createEventReducer.duplicate == true ? 'Please enter new project name': ''} >
        <CustomInput
          // id= {errorObjectArray[0]?.id}
          onFocus={() =>{ setNameValidationFlag(false); setTimeout(()=>{setNametooltip(true)},[1500]) }}
          onBlur={() => setNameValidationFlag(true)}
          autoFocus={createEventReducer.duplicate}
          required
          errorFlag={
            nameValidationFlag &&
            getErrorMsg("event_name", pageData?.name) != ""
          }
          errorMsg={getErrorMsg("event_name", pageData?.name)}
          fullWidth
        //   label="Event Name*"
          type="text"
          max={100}
          placeholder="Lucivideo Name (Max Character 100)"
          onChange={(event) =>
           {
            setNametooltip(false)
             setInput(
              pageData?.name != ""
                ? event.target.value.replace(/[<>:"/\\|?*]/g, '')
                : event.target.value.trim(),
              "name",
              pageData,
              setPageData
            )}
          }
          value={pageData?.name}
        />
        </CustomTooltip>
         <Typography sx={{fontFamily: 'Rubik',fontSize:{xs:'6px',md:'7px',lg:'8px',xl:'11px'}, paddingLeft:'15px'}}><span style={{fontWeight:600}}>Note:</span> The title of you video <span style={{fontWeight:600}}>cannot</span> be changed once you’ve created it.</Typography>
         </Box>

{/* //////////////////////////////event type and video length /////////////////////////////////////////// */}

        <Box sx={{width:'100%', display:'flex', marginBottom:{xs:'25px',sm:'25px',md:'25px',lg:'25px',xl:'33px'}}} gap={4}>
            <Box flex={1} >

                        <CustomAutocomplete
                            id="event_type"
                            options={eventType}
                            onFocus={() => {
                              setNameValidationFlag(true)
                              setEventTypeValidationFlag(false)
                              }}
                            onBlur={() => setEventTypeValidationFlag(true)}
                            required
                            // errorFlag={eventTypeValidationFlag && getErrorMsg("event_type", pageData?.quality_options?.length > 0 && pageData?.quality_options[0]) != ""}
                            // errorMsg={getErrorMsg("quality_option", pageData?.quality_options?.length > 0 && pageData?.quality_options[0])}
                            errorFlag= {eventTypeValidationFlag && getErrorMsg("event_type", pageData?.event_type_id) != ""}
                            errorMsg= {getErrorMsg("event_type", pageData?.event_type_id)}
                            value={selectedEventType(pageData?.event_type_id)}

                            onChange={(event, newValue) => {
                                setPageData((last) => {
                                    return { ...last, event_type_id: [newValue.id] };
                                });
                            }}
                            label=""
                            placeholder='Event Type'
                            // id="Department"
                            //  required
                            //  options={departmentdata?.data?.department}
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? option.value : ""
                            }
                
                        />
   
           
          </Box>
          <Box flex={1} >
                       
                         <CustomAutocomplete
                            id="video_length"
                            options={videoLength}
                            onFocus={() => {
                            setEventTypeValidationFlag(true)
                            setVideoLengthValidationFlag(false)
                            }}
                            onBlur={() => setVideoLengthValidationFlag(true)}
                            required
                            errorFlag={videoLengthValidationFlag && getErrorMsg("video_length", pageData?.video_length_id) != ""}
                            errorMsg={getErrorMsg("video_length", pageData?.video_length_id)}
                            value={selectedVideoLength(pageData?.video_length_id)}

                            onChange={(event, newValue) => {
                                setPageData((last) => {
                                    return { ...last, video_length_id: [newValue.id] };
                                });
                            }}
                            label=""
                            placeholder='Select the length of your Lucihub video'
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0 ? convertSecondsToMinutes(option?.value) : ""
                            }
                            // getOptionLabel={(option) =>
                            //     Object.keys(option).length > 0 ? `${option.value} Seconds` : ""
                            // }
                        />
             </Box>
        </Box>

        {/* /////////////////////event start and end date time picker///////////////////////////////////////// */}

        <Box sx={{width:'100%', display:'flex',
        //  marginBottom:{xs:'7px',sm:'12px',md:'15px',lg:'20px',xl:'33px'}
         }} gap={4}>
             <Box flex={1}>
                        {/* <DateTimePicker 
                          placeholder='When does filming start?'
                          onFocus={() => {
                            setNameValidationFlag(true);
                            setEventTypeValidationFlag(true)
                            setVideoLengthValidationFlag(true)
                            setStartDateValidationFlag(false);
                          }}
                          disabled={createEventReducer.pageData?.start_date !="" && !isEventPending(createEventReducer.pageData?.start_date)}
                          onBlur={() => setStartDateValidationFlag(true)}
                         // required
                          required = {createEventReducer.pageData?.start_date !="" ? isEventPending(createEventReducer.pageData?.start_date): true}
                          errorFlag={startDateValidationFlag && getErrorMsg("start_date_time", pageData?.start_date) != ""   }
                          errorMsg={getErrorMsg("start_date_time", pageData?.start_date)}
                          defaultValue={
                            pageData?.start_date != ""
                            ? new Date(pageData?.start_date * 1000)
                            : null
                          }
                          invalidDateMessage={null}
                          minDateMessage={null}
                          maxDateMessage={null}
                          label="Start date & time"
                          disablePast
                          animateYearScrolling
                          fullWidth
                          onDateChange={(date) => {
                            setPageData((last) => {
                            return {
                              ...last,
                              start_date: date
                             };
                           });
                          }}
                          dateTime={projectStartDateTime}
                          setDateTime={setProjectStartDateTime}
                         /> */}
            </Box>
            <Box flex={1}>
                 {/* <DateTimePicker 
                    placeholder='When does filming end?'
                    onFocus={() => {
                      setNameValidationFlag(true)
                      setEventTypeValidationFlag(true)
                      setVideoLengthValidationFlag(true)
                      setStartDateValidationFlag(true);
                      setEndDateValidationFlag(false);
                    }}
                    onBlur={() => setEndDateValidationFlag(true)}
                    required
                    right='0px'
                    errorFlag={
                      endDateValidationFlag &&
                      getErrorMsg(
                      "end_date_time",
                      pageData?.end_date,
                      pageData?.start_date
                      ) != ""
                    }
                    errorMsg={getErrorMsg(
                     "end_date_time",
                      pageData?.end_date,
                      pageData?.start_date
                    )}
                    disabled={pageData?.start_date === ""}
                    disablePast
                    label="End date & time"
                    defaultValue={
                      pageData?.end_date != ""
                      ? new Date(pageData?.end_date * 1000)
                      : null
                     }
                    onDateChange={(date) => {
                            setPageData((last) => {
                            return {
                              ...last,
                              end_date: date
                             };
                           });
                          }}
                 /> */}
                  {/* <DateTimePicker 
                  placeholder='When do you want editing to begin?'
                  onFocus={() => {
                    setNameValidationFlag(true)
                    setEventTypeValidationFlag(true)
                    setVideoLengthValidationFlag(true)
                      setStartDateValidationFlag(true)
                    //  setEndDateValidationFlag(true);
                      setEditorStartDateValidationFlag(false);
                    }}
                    onBlur={() => setEditorStartDateValidationFlag(true)}
                    required
                    errorFlag={
                      editorStartDateValidationFlag &&
                      getErrorMsg(
                      "editor_start_date_time",
                      pageData?.editor_start_date,
                      pageData?.start_date,
                      ) != ""
                    }
                    errorMsg={getErrorMsg(
                      "editor_start_date_time",
                      pageData?.editor_start_date,
                      pageData?.start_date,
                    )}
                    disabled={pageData?.start_date == ""}
                    disablePast
                   // label="End date & time"
                    defaultValue={
                      pageData?.editor_start_date != ""
                      ? new Date(pageData?.editor_start_date * 1000)
                      : null
                     }
                    onDateChange={(date) => {
                            setPageData((last) => {
                            return {
                              ...last,
                              editor_start_date: date
                             };
                           });
                          }}
                          dateTime={editStartDateTime}
                          setDateTime={setEditStartDateTime}
               /> */}
            </Box>
        </Box>

        {/* ////////////////////////////editing start time picker//////////////////////////////////////////// */}
        
          <Box sx={{width:'100%', display:'flex', justifyContent: 'center', alignItems:'center',  marginBottom:{xs:'7px',sm:'12px',md:'15px',lg:'20px',xl:'33px'}}} gap={5}>
            <Box sx={{ flex:1}}>   
            <DateTimePicker 
                  placeholder='When do you want editing to begin?'
                  onFocus={() => {
                    setNameValidationFlag(true)
                    setEventTypeValidationFlag(true)
                    setVideoLengthValidationFlag(true)
                      setStartDateValidationFlag(true)
                    //  setEndDateValidationFlag(true);
                      setEditorStartDateValidationFlag(false);
                    }}
                    onBlur={() => setEditorStartDateValidationFlag(true)}
                    required
                    errorFlag={
                      editorStartDateValidationFlag &&
                      getErrorMsg(
                      "editor_start_date_time",
                      pageData?.editor_start_date,
                      pageData?.start_date,
                      ) != ""
                    }
                    errorMsg={getErrorMsg(
                      "editor_start_date_time",
                      pageData?.editor_start_date,
                      pageData?.start_date,
                    )}
                    disabled={pageData?.start_date == ""}
                    disablePast
                   // label="End date & time"
                    defaultValue={
                      pageData?.editor_start_date != ""
                      ? new Date(pageData?.editor_start_date * 1000)
                      : null
                     }
                    onDateChange={(date) => {
                            setPageData((last) => {
                            return {
                              ...last,
                              editor_start_date: date
                             };
                           });
                          }}
                          dateTime={editStartDateTime}
                          setDateTime={setEditStartDateTime}
               />
             </Box>
             <Box textAlign='justify' sx={{display:'flex', alignItems:'center', justifyContent:'start', flex:1}}>
                <FormGroup sx={{fontSize:'11px', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <FormControlLabel sx={{marginRight:0}} control={<Checkbox checked={checked} onChange={handleChange} sx={{ '& .MuiSvgIcon-root': { fontSize:{xs:'9px',sm:'10px',lg:'14px',xl:'16px'}, margin:0, padding:0,} , borderRadius:'5px'}} />}  />             
                </FormGroup>
                <Typography  sx={{fontFamily: 'Rubik', fontSize:{xs:'6px',sm:'7px',lg:'9px',xl:'11px'}}}>I acknowledge no new videos can be submitted after the date you specified: 
                  <span style={{fontFamily: 'Rubik', color:'#C10000', fontWeight:600, paddingLeft:'5px'}}>
                    {pageData?.editor_start_date ? timeStampToDateString(pageData?.editor_start_date): 'MM/DD/YYYY'}
                  </span>
                </Typography>
             </Box>
          </Box>

          {/* ///////////////////////////phone orientation///////////////////////////////////////////////// */}

          <Box sx={{width:'100%', display:'flex', flexDirection:'column', marginBottom:{xs:'10px',md:'15px',lg:'20px',xl:'42px'}}} textAlign='left'>
            <Typography sx={{marginBottom:{xs:'11px',lg:'15px',xl:'18px'}, fontFamily: 'Rubik', fontWeight:600, fontSize:{xs:'8px',lg:'11px',xl:'13px'}}}>Select the orientation of your Lucivideo:</Typography>
            <Box sx={{display:'flex'}} gap={4}>
                <Box flex={1} sx={{display:'flex'}} gap={5}>
             
                  {/* <Box flex={1} sx={{display:'flex', flexDirection:'column'}}> */}
                    <Box sx={{border:pageData.video_orientation === 'landscape'? `3px solid ${theme.palette.primary.main}`:'1px solid black', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:'center', height:{xs:'70px',md:'70px',lg:'83.33px',xl:'125px'}, width:{xs:'80px',sm:'100px',md:'120px',lg:'137px',xl:'205px'}, cursor:'pointer', backgroundColor: pageData.video_orientation === 'landscape'? `${theme.palette.primary.main}65`:'white'}} flex={1} p={1} id='landscape' onClick={selectLandscape}>
                            {/* <CustomClickableCard style={{ boxShadow: 'none',}} id='landscape' clickHandler={selectLandscape} >
                                <img src={landscapeSelected} style={{ display: pageData?.video_orientation === "landscape" ? 'block' : 'none' }} width='80px' />
                                <img src={landscape} style={{ display: pageData?.video_orientation === "landscape" ? 'none' : 'block' }} width='80px' />
                            </CustomClickableCard>  */}
                            <Box sx={{width:{xs:'50px',sm:'60px',md:'68px',lg:'79px',xl:'118.52px'}, border:{xs:`1.5px solid  ${theme.palette.primary.main}`,sm:`2px solid   ${theme.palette.primary.main}`,lg:`2.5px solid   ${theme.palette.primary.main}`,xl:`3px solid  ${theme.palette.primary.main}`}, height:{xs:'35px',md:'40px',lg:'46px',xl:'69.19px'}, borderRadius:'5px', display:'flex'}}>
                              <Box sx={{borderRight:{xs:`1.5px solid  ${theme.palette.primary.main}`,sm:`2px solid   ${theme.palette.primary.main}`,lg:`2.5px solid   ${theme.palette.primary.main}`,xl:`3px solid  ${theme.palette.primary.main}`}, width:{xs:'5px',sm:'6px',md:'7px',lg:'8px',xl:'10px'}}}></Box>
                              <Box flexGrow={1} sx={{display:'grid', placeItems:'center'}}>
                                <Typography style={{fontFamily: 'Rubik', color: pageData.video_orientation ==='landscape'? 'white' : 'black'}}  sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13.5px',xl:'20px'}, fontWeight:600,}}>16:9</Typography>
                              </Box>
                              <Box sx={{borderLeft: {xs:`1.5px solid  ${theme.palette.primary.main}`,sm:`2px solid   ${theme.palette.primary.main}`,lg:`2.5px solid  ${theme.palette.primary.main}`,xl:`3px solid  ${theme.palette.primary.main}`}, width:{xs:'5px',sm:'6px',md:'7px',lg:'8px',xl:'10px'}}}></Box>
                            </Box>
                    </Box>
                  {/* </Box> */}
                    
      
                 

                {/* <Box flex={1}> */}
                    <Box sx={{border:pageData.video_orientation === 'portrait'? `3px solid ${theme.palette.primary.main}`:'1px solid black', borderRadius:'5px', display:'flex', alignItems:'center', justifyContent:'center',height:{xs:'70px',md:'70px',lg:'83.33px',xl:'125px'}, cursor:'pointer', backgroundColor: pageData.video_orientation === 'portrait'? ` ${theme.palette.primary.main}65`:'white'}} flex={1} p={1} id='portrait' onClick={selectPortrait}>
                    
                           <Box sx={{width:{xs:'28px',sm:'34px',md:'38px',lg:'42.22px',xl:'63.33px'}, border:{xs:`1.5px solid   ${theme.palette.primary.main}`,sm:`2px solid  ${theme.palette.primary.main}`,lg:`2.5px solid  ${theme.palette.primary.main}`,xl:`3px solid ${theme.palette.primary.main}`}, height:{xs:'55px',md:'65px',lg:'72.3px',xl:'108.49px'}, borderRadius:'5px', display:'flex', flexDirection:'column'}}>
                              <Box sx={{borderBottom:{xs:`1.5px solid  ${theme.palette.primary.main}`,sm:`2px solid   ${theme.palette.primary.main}`,lg:`2.5px solid   ${theme.palette.primary.main}`,xl:`3px solid  ${theme.palette.primary.main}`}, height:{xs:'5px',sm:'6px',md:'7px',lg:'8px',xl:'10px'}}}></Box>
                              <Box flexGrow={1} sx={{display:'grid', placeItems:'center',}}>
                                <Typography style={{fontFamily: 'Rubik', color: pageData.video_orientation ==='portrait'? 'white' : 'black'}} sx={{fontSize:{xs:'10px',sm:'11px',md:'12px',lg:'13.5px',xl:'20px'}, fontWeight:600,}}>9:16</Typography>
                              </Box>
                              <Box sx={{borderTop: {xs:`1.5px solid  ${theme.palette.primary.main}`,sm:`2px solid   ${theme.palette.primary.main}`,lg:`2.5px solid   ${theme.palette.primary.main}`,xl:`3px solid  ${theme.palette.primary.main}`}, height:{xs:'5px',sm:'6px',md:'7px',lg:'8px',xl:'10px'}}}></Box>
                            </Box>
                          {/* </Box> */}
                    </Box>
                </Box>
                <Box flex={1} sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'7px',md:'8px',lg:'9px',xl:'13px'}}}><Typography sx={{fontSize:{xs:'9px',sm:'9px',md:'12px',lg:'13.5px',xl:'20px'}, fontWeight:600, display:'inline'}} mr={2}>16:9</Typography>  <span style={{fontWeight:600}}>Most common</span> and great for most screens</Typography>
                    <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'7px',md:'8px',lg:'9px',xl:'13px'}}}><Typography sx={{fontSize:{xs:'9px',sm:'9px',md:'12px',lg:'13.5px',xl:'20px'}, fontWeight:600, display:'inline'}} mr={2}>9:16</Typography>  Good for Reels, Stories, Shorts, & TikTok’s</Typography>
                </Box>
                
            </Box>
            <Typography mt={0.6} ml={{xs:4,sm:5,md:6,lg:7,xl:9.5}} sx={{fontFamily: 'Rubik', fontSize:{xs:'5px',sm:'6px',lg:'7.33px',xl:'11px'}, fontWeight:600, fontStyle:'italic'}}>Recommended</Typography>
          </Box>

    {/* ///////////////////////////////////////video description////////////////////////////////////////////// */}

           <Box sx={{marginBottom:{xs:'10px',sm:'10px',md:'15px',lg:'17.33px',xl:'26px'}}}>
       
          <CustomTextbox
          onFocus={() =>{ 
            setNameValidationFlag(true)
            setEventTypeValidationFlag(true)
            setVideoLengthValidationFlag(true)
            setStartDateValidationFlag(true)
            setEditorStartDateValidationFlag(true);
            setSongTypeValidationFlag(false) }}
         // onBlur={() => setSongTypeValidationFlag(true)}
          // required
          // errorFlag={
          //   songTypeValidationFlag &&
          //   getErrorMsg("song_type", pageData?.song_type) != ""
          // }
          // errorMsg={getErrorMsg("song_type", pageData?.song_type)}
          fullWidth
        //   label="Event Name*"
          type="text"
          max={500}
          // rows={1}
          minRows={2}
                    multiline={true}
                maxRows={2}
                lineHeight='16px'
        
          placeholder='What type of music do you want for your event, ie genre, style or mood'
          onChange={(event) =>
           {
            setNametooltip(false)
             setInput(
              pageData?.song_type != ""
                ? event.target.value
                : event.target.value.trim(),
              "song_type",
              pageData,
              setPageData
            )}
          }
          value={pageData?.song_type}
        />
          </Box>

                        {/* <Box sx={{marginBottom:{xs:'10px',sm:'10px',md:'15px',lg:'17.33px',xl:'26px'}}}>
                                <input
                                    accept="audio/*"
                                    // className={classes.input}
                                    onClick={event => event.target.value = ''}
                                    id="contained-button-song"
                                    style={{display:'none'}}
                                    // multiple
                                    type="file"
                                    // onChange={(event) => songSelectHandler(event)}
                                    onChange={(event, newValue) => {
                                          setPageData((last) => {
                                              return { ...last, song: event.target.files[0]?.type?.includes('audio') ? event.target.files[0] : null};
                                          });
                                          if(event.target.files[0]?.type?.includes('audio')){
                                            setSongFormatError(false)
                                          }else{
                                            setSongFormatError(true)
                                          }
                                    }}
                                />
                            <Box sx={{display:'flex', gap:1, alignItems:'center'}}>
                            <label htmlFor="contained-button-song">
                                <Typography textAlign={'left'} variant='body1' sx={{fontSize:'12px', fontWeight:600, fontFamily:'Rubik', cursor:'pointer', textDecoration:'underline', color:'navy'}} >Upload Song</Typography>
                            </label>
                        <Typography textAlign={'left'} variant='body1' sx={{fontSize: songFormatError?'10px': '11px', fontWeight:600, fontFamily:'Rubik', ml:1, color: songFormatError && 'red'}} >{songFormatError ? 'Please upload file in valid format. (mp3, aac, ogg)': pageData?.song?.name}</Typography>
                           {pageData?.song?.name && !songFormatError && <CloseRoundedIcon 
                                onClick={()=>setPageData((last) => {
                                          return { ...last, song: null }
                                        })} 
                                style={{color:'red', fontSize:'12px', cursor:'pointer'}} 
                            />}
                            </Box>
                        </Box> */}

          <Box sx={{marginBottom:{xs:'10px',sm:'15px',md:'15px',lg:'20px',xl:'57px'}}}>
            <TextField 
            fullWidth
           // txtStyle = {{height: '100%'}}
            multiline
            variant='outlined'
            // rows={2}
            maxRows={2}
            minRows={2}
            // rowsMax={2}
            size="small"
            inputProps={{
            // fullWidth: true,
            maxLength: 500
      
          }}
            style={{backgroundColor:'white'}}
           sx={{
            color:'#08231B',
                border:'1px solid #08231B',
                borderRadius:'5px',
                backgroundColor:'white',
                fontFamily: 'Rubik',
            '& .MuiInputBase-input': {
                        padding:0,
                        paddingX: '4px',
                        fontSize: {xs:'8px',lg:'10px',xl:'13px'},
                        fontWeight: 600,
                        // color: theme.palette.inputs.main
                        color:'#08231B',
                        lineHeight:'16px',
                        "&::placeholder": {    // <----- Add this.
                            opacity: 1,
                            fontWeight: 600,
                    },
              },
           }}
        
            type="text"
            // inputProps={{
            //   maxLength: 600
            // }}
            shrink
            // placeholder="Hi , I would like to ..."
            placeholder='What should your editor know about your story, key people, locations or shots to look out for?'
            onChange={(event) => setInput(pageData?.description != "" ? event.target.value : event.target.value.trim() , "description", pageData, setPageData)}
            value={pageData?.description}
            onFocus={() =>{ 
              setNameValidationFlag(true)
              setEventTypeValidationFlag(true)
              setVideoLengthValidationFlag(true)
              setStartDateValidationFlag(true)
              setEditorStartDateValidationFlag(true);
             }}
            // required
            // error={submitFlag && getErrorMsz('name', pageData.name) != ""}
            // errorMsz={getE
            />          
          </Box>

          
          {/* <Box sx={{marginBottom:{xs:'10px',sm:'15px',md:'15px',lg:'20px',xl:'57px'}}}>
                                <input
                                    accept=".pdf, .doc, .docx"
                                    // className={classes.input}
                                    id="contained-button-file"
                                    onClick={event => event.target.value = ''}
                                    style={{display:'none'}}
                                    // multiple
                                    type="file"
                                    // onChange={(event) => songSelectHandler(event)}
                                    // onChange={(event, newValue) => {
                                    //       setPageData((last) => {
                                    //           return { ...last, note: event.target.files[0]?.type?.includes('application') ? event.target.files[0] : null };
                                    //       });
                                    //       console.log(event.target.files);
                                    //       if(event.target.files[0]?.type?.includes('application')){
                                    //         setFileFormatError(false)
                                    //       }else{
                                    //         setFileFormatError(true)
                                    //       }
                                    // }}
                                    onChange={(event, newValue) => uploadNotesHandler(event, newValue)}
                                />
                            <Box sx={{display:'flex', gap:1, alignItems:'center'}}>
                            <label htmlFor="contained-button-file">
                                <Typography textAlign={'left'} variant='body1' sx={{fontSize:'12px', fontWeight:600, fontFamily:'Rubik', cursor:'pointer', textDecoration:'underline', color:'navy'}} >Upload Notes</Typography>
                            </label>
                            <Typography textAlign={'left'} variant='body1' sx={{fontSize:fileFormatError ? '10px' :'11px', fontWeight:600, fontFamily:'Rubik', ml:1, color: fileFormatError && 'red'}} >{fileFormatError ? notesErrorMessage : (!pageData?.note ? "(Maximum 10 MB, PDF, DOC, DOCX, or RTF only)" : pageData?.note?.name )}</Typography>
                           {pageData?.note?.name && !fileFormatError && <CloseRoundedIcon 
                                onClick={()=>setPageData((last) => {
                                          return { ...last, note: null }
                                        })} 
                                style={{color:'red', fontSize:'12px', cursor:'pointer'}} 
                            />}
                            </Box>
                        </Box> */}

    {/* /////////////////////////////////////create event Button//////////////////////////////////////////// */}
         
    
            <Box sx={{ display:'flex', justifyContent:'flex-end' }} >
           
              <Box >
          
            { requestStatus == 'loading' || requestStatus == 'success' ? <LoadingButton
                   // size="small"
                    sx={{
                        '& .MuiLoadingButton-loadingIndicator': {
                            color:  theme.palette.primary.main,
                        },
                        height: {xs:'36px',sm:'40px',md:'45px', lg:'51px',xl:'75px'},
                       minWidth: {xs:'36px !important',sm:'40px !important',md:'45px !important', lg:'51px !important',xl:'75px !important'},
                        backgroundColor: '#08231B'
                    }}
                    loading={requestStatus == 'loading'}
                    variant="outlined"
                    disabled
                    >
                   
               </LoadingButton> :
               <CheckIcon onClick= {finishedHandler} color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)}  sx={{fontSize:{xs:'40px',sm:'44px',md:'50px', lg:'56px',xl:'82px'}, cursor: checked && 'pointer', opacity: !checked && 0.5}}/>
               }
              </Box>
             

                {/* <CustomButton  
                style={{
                    width: 100,
                    borderWidth: 2,
                    fontSize: 14,
                    fontWeight: 600,
                    boxShadow:  '0px 4px 10px rgba(0, 0, 0, 0.25)',
                    borderRadius: 10}}
                    // disabled={requestStatus === "loading" }
                     disabled={!checked}
                     onClick= {finishedHandler} 
                    btnText = "FINISH"  
                    variant="contained"
                    /> */}
            </Box>
      
    

           {/* ///////////////////////////////////////////////////////////////////////////////////////////////////// */}

        </Box>
    </>
}

export default CreateEvent;