import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { Typography } from "@mui/material";
import "@fontsource/rubik";
import { useDispatch, useSelector } from "react-redux";
import { resetCreationState, updateCreationState } from "../../../redux/eventCreationSlice";
// import { customTheme as theme } from "../../../theme/customTheme";
import useCustomTheme from "../../../theme/useCustomTheme";

const drawerWidth = 350;

const CustomDrawer = (props) => {
  const { openDrawer, setOpenDrawer, bgColor ,handleDrawerToggle} = props;
  const createEventReducer = useSelector((state) => state.createEventReducer);
  const themeReducer = useSelector((state) => state.root.themeReducer);
  const dispatch = useDispatch()
  const [theme ] = useCustomTheme()
     // const brandLogo = window.localStorage.getItem('brand_logo_url')
    //  const primaryColor = localStorage.getItem('primary')
     const primaryColor = themeReducer?.primary

  const inlineStyles = {
    background: `
    linear-gradient(180deg, #ffffff26 ,#ffffff26  ),
    linear-gradient(120deg, ${theme.palette.secondary.main}36,  transparent, transparent,transparent  70%),
    linear-gradient(150deg, transparent,  ${theme.palette.primary.main}36, #EBEBEB50, transparent,#ffffff20, transparent  90%),
    linear-gradient(150deg, transparent,#ffffff20 ,transparent,transparent, ${theme.palette.secondary.main}25 ,  transparent,transparent,transparent 140%),
    linear-gradient(120deg, transparent ,transparent, transparent,  #ffffff20, ${theme.palette.primary.main}36  ,transparent 120%)
    `
  };
  // const handleDrawerToggle = () => {
  //   //setOpenDrawer((last) => !last);
  //   dispatch(resetCreationState())
  // };

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} >
      <CssBaseline />
      <Box
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}  
       
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // {...props}
          anchor="right"
          variant="temporary"
         open={openDrawer}
        //  open = {createEventReducer.open_form}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
         classes={'linear-gradient-drawer'}
          sx={{
            // display: { xs: "block", sm: "block", md: "none" },
            // width: "90vw",
            // minWidth: 250,
            // maxWidth: 400,
            "& .MuiDrawer-paper": {
              width: {xs:'100vw',sm:'80vw',md:'70vw',lg:'60vw'},
              // backgroundColor: bgColor,
              height:'100%'
            },
          }}
          transitionDuration={1000}
        >
          <Box style={primaryColor ?  inlineStyles: {}} className='linear-gradient-drawer-bottom ' sx={{height:'100%', width:'100%', paddingLeft:{xs:'20px',sm:'30px',md:'50px',lg:'70px', xl:'82px'},paddingRight:{xs:'20px',sm:'20px',md:'35px',lg:'45px', xl:'57px', display:'flex', flexDirection:'column'}, overflowY:'auto',  scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': {display: 'none'}}} textAlign='right'>
            <Box className='flex row j-end' >    
                <Typography onClick={handleDrawerToggle} sx={{fontFamily: 'Rubik', fontSize:{xs:'8px',md:'11px', xl:'14px'}, width: '60px', fontWeight:600, marginTop:{xs:'10px',sm:'15px',md:'20px',lg:'20px',xl:'44px'}, marginBottom:{xs:'10px',md:'14px',lg:'12px',xl:'24px'}, cursor:'pointer'}}>Close X</Typography>
            </Box>

            {props.children}
            
          </Box>
        </Drawer>
       
      </Box>
    </Box>
  );
};

// CustomDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default CustomDrawer;