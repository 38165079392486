import { BRANDBOX_OPTIONS } from "../../constants/staticData";
import { setAssetState } from "../../redux/assetSlice";
import { setDropDownArray } from "../../redux/dropdownSlice";
import { setSnackbar } from "../../redux/messageSlice";
import { createAssetApi, deleteAssetFileApi, getAllAssetsApi, getAssetOptionsApi } from "../../services/api";
import { showSnackBar } from "../../utils/commonServices";


export const getAssetOptionsList = (dispatch) => {
getAssetOptionsApi() 
.then((response) => {

  if(response?.status === 200){
     dispatch(setDropDownArray({asset_options: response.data.label}))
  }else {
      dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
  }
})
.catch((error) => {
  if(error) {
  const errorObject = error?.response?.data;
  showSnackBar(dispatch, errorObject);
  }
});
}

export const getAllAssets = (dispatch, navigate, state ) => {
  dispatch(setAssetState({is_loading: true}))
  getAllAssetsApi()
   .then((response) => {
     dispatch(setAssetState({is_loading: false}))
     if(response?.data?.http_code === 200){
          console.log(response.data.data, 'response get assets ---------------------------')
          if(Array.isArray(response.data.data)){
            dispatch(setAssetState({ all_assets: response.data.data, }));
          }
     }else {
         dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
     }
   })
   .catch((error) => {
     if(error) {
     dispatch(setAssetState({is_loading: false}))
     const errorObject = error?.response?.data;
     showSnackBar(dispatch, errorObject);
     }
   });
}


export const createAsset = (values ,dispatch, callback) => {
    dispatch(setAssetState({is_loading: true}))
  const payload = values
  createAssetApi(payload) 
  .then((response) => {
    dispatch(setAssetState({is_loading: false}))
    if(response?.data?.http_code === 200){
      callback()
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
      getAllAssets(dispatch)
      // getAllAssets(dispatch)
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(setAssetState({is_loading: false}))
    if(error) {
      console.log(error,'Error in creating asset');
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    }
  });
  }

  export const deleteAsset = (values ,dispatch, callback) => {
    dispatch(setAssetState({is_loading: true, delete_asset_loading:true}))
  const payload = values
  deleteAssetFileApi(payload) 
  .then((response) => {
    dispatch(setAssetState({is_loading: false, delete_asset_loading:false}))
    if(response?.data?.http_code === 200){
      callback()
      dispatch(setSnackbar({type:'success', flag:true, message: response?.data?.message}))
      getAllAssets(dispatch)
    
    }else {
        dispatch(setSnackbar({type:'error', flag:true, message: 'some error occured'}))
    }
  })
  .catch((error) => {
    dispatch(setAssetState({is_loading: false, delete_asset_loading:false}))
    if(error) {
    const errorObject = error?.response?.data;
    showSnackBar(dispatch, errorObject);
    }
  });
  }