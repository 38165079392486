import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
// import { customTheme as theme } from "../../../theme/customTheme";
import { Box } from "@mui/system";
import { InputAdornment, Typography } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from "react";
import "@fontsource/rubik";
import useCustomTheme from "../../../theme/useCustomTheme";


const CustomAutocomplete = (props) => {
  const { required, errorFlag, errorMsg, ref, medium , msgColor} = props;
  const [theme ] = useCustomTheme()


  //const {options, getOptionLabel} = props;
  // options: top100Films,
  // getOptionLabel: (option) => option.title,

  //   const flatProps = {
  //     options: top100Films.map((option) => option.title),
  //   };

  //   const [value, setValue] = React.useState(null);

  return (
    <Box sx={{height: {xs:'20px',md:'25px',lg:'30px',xl:'40px'}}}>
      <Autocomplete
        ref={ref}
        popupIcon={<KeyboardArrowDownIcon  sx={{color:'black', fontSize: medium ? '24px !important': '14px !important'}} />}
        size={medium ? 'medium': 'small'}
        id="disable-close-on-select"
        disableClearable
        style={{backgroundColor:'white', borderRadius:'5px'}}
        ListboxProps={{
          sx: {
            backgroundColor:'white',
            // color: theme.palette.primary.light,
            fontSize:  medium ?  {xs:'10px',lg:'12px',xl:'15px'} :  {xs:'8px',lg:'10px',xl:'13px'},
            "& :hover": {
              // color: theme.palette.primary.main,
              backgroundColor:`${theme.palette.primary.main}70 !important`,
              color:'black !important',
              fontWeight:600
            },
            '& .MuiAutocomplete-option[aria-selected="true"]': {
              // works
              // backgroundColor: theme.palette.secondary.light,
              backgroundColor:`${theme.palette.primary.main}99`,
              // color: theme.palette.secondary.contrastText,
              color:'black',
              fontWeight:600
            },
            '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
              // works
              // backgroundColor: theme.palette.secondary.light,
              backgroundColor:`${theme.palette.primary.main}70`,
              // color: theme.palette.secondary.contrastText,
              color:'black',
              fontWeight:600
            },
          },
        }}
       
        sx={{
          "& .MuiAutocomplete-root": {
            backgroundColor:'white'
          },
          "& .MuiAutocomplete-inputRoot": {
            color: theme.palette.primary.light,
            fontSize:{xs:'8px',lg:'10px',xl:'13px'},
            border:'1px solid black',
            backgroundColor:'white'
          },
          "& .MuiAutocomplete-option": {
            color: theme.palette.primary.light,
            fontSize: {xs:'8px',lg:'10px',xl:'13px'},
          },
          "& .MuiAutocomplete-endAdornment": {
           top:'calc(40% - 8px)',
           transform: 'none'
          },
         
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            value={props.value}
            {...params}
            // label={props.label}
            placeholder={props.placeholder}
            variant="outlined"
   
            InputProps={{
              ...params.InputProps,
      
          //     endAdornment: (
          //   <InputAdornment position="end">
          //     <AccountCircle />
          //   </InputAdornment>
          // ),
            }}
            sx={{ fontSize: {xs:'8px',lg:'9px',xl:'13px'}, 
              
             input: {
              fontFamily: 'Rubik',
              fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} :  {xs:'8px',lg:'9px',xl:'13px'},
              fontWeight: 600,
              color:'#08231B',
              "&::placeholder": {    // <----- Add this.
                opacity: 1,
            },
            
            //   color: theme.palette.inputs.light,
            },}}
          />
        )}
        {...props}
      />
      {required && errorFlag && (
        <Typography
          textAlign={'right'}
          variant="body1"
          className="errorDom"
          component="span"
          sx={{fontFamily: 'Rubik', color:msgColor? msgColor : "rgb(211, 47, 47)",  fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'6px',md:'7px',lg:'8px',xl:'13px'}, fontWeight: medium && 600 }}
        >
          <Box  >{errorMsg}</Box>
        </Typography>
      )}
    </Box>
  );
};
export default CustomAutocomplete;