import { Backdrop, Box, Slide, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import CustomInput from './CustomInput';
import { updateCreationState } from '../../../redux/eventCreationSlice';
import { useState } from 'react';
import { afterValidate, generateLighterContrastColor, setInput } from '../../../utils/commonServices';
import { getErrorMsg } from '../../../utils/validator';
import DownIcon from '../icons/DownIcon';
import UpIcon from '../icons/UpIcon';
// import { customTheme as theme } from '../../../theme/customTheme';
import { getContrastRatio } from '@mui/material/styles';
import useCustomTheme from '../../../theme/useCustomTheme';

const ProjectNamePage = (props) => {

    const {pageData, setPageData, slideDirection, nextPageHandler, prevPageHandler, pageIndex, selectedProjectType, setSelectedProjectType, totalScreens, closeHandler} = props
    const createEventReducer = useSelector((state) => state.createEventReducer);
    const dispatch = useDispatch()
    const [nameValidationFlag, setNameValidationFlag] = useState(false);
    const [nameTooltip, setNametooltip] = useState(false)
    const [theme ] = useCustomTheme()

    const finishedHandler = ()=> {
        validateInputs(true)
        afterValidate(nextPageHandler);
    }

    const validateInputs = (flag) => {
        setNameValidationFlag(flag)
    }

    useEffect(() => {
      const keyDownHandler = (event) => {
        // console.log("key Parent", event.key)
        if (event.key === "Enter") {
          event.preventDefault();
  
          finishedHandler();
        }
      };
        document.addEventListener("keydown", keyDownHandler)
        
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [pageData]);


  return (
    <>
    <Slide direction={slideDirection} in={true} mountOnEnter unmountOnExit>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 , backdropFilter: 'blur(23px) !important', display:'flex', flexDirection:'column', background: '#08231B99 0% 0% no-repeat padding-box', }}
    open={createEventReducer.open_backdrop_project_creation}
    >
        <Box sx={{ width:'100%', height:'100%', display:'flex', flexDirection:'column'}}>
          <Typography textAlign={'right'} sx={{fontSize:'16px', cursor:'pointer', mt:5, mr:3, fontFamily:'Rubik'}} onClick={closeHandler}>Close X</Typography>
          <Box flexGrow={1} className='flex col ' sx={{ paddingLeft:'14vw', marginTop:{xs:'20%',sm:'14%',md:'8%', lg:'7%'}}}>
              <Box mb={6}>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>{`${pageIndex}/${totalScreens}`}</Typography>
                <Typography sx={{fontSize:{xs:'30px',sm:'35px',md:'40px',lg:'45px',xl:'65px'}, color:'white', fontFamily:'Rubik'}}>What is the name of your project?</Typography>
                <Typography sx={{fontSize:{xs:'15px',sm:'18px',md:'22px',lg:'24px',xl:'35px'}, color:'white', fontFamily:'Rubik'}}>The title of project appears on the opening card of the video</Typography>
              </Box>
              <Box className='flex' sx={{width:'80%'}}>
              <CustomInput
                // id= {errorObjectArray[0]?.id}
                onFocus={() =>{ setNameValidationFlag(false); setTimeout(()=>{setNametooltip(true)},[1500]) }}
                onBlur={() => setNameValidationFlag(true)}
                type='sendInput'
                required
                medium
                errorFlag={
                  nameValidationFlag &&
                  getErrorMsg("project_name", pageData?.name) != ""
                }
                errorMsg={getErrorMsg("project_name", pageData?.name)}
                fullWidth
                max={100}
                placeholder="Lucihub Project Name (Max Character 100)"
                onChange={(event) =>
                {
                  setNametooltip(false)
                  setInput(
                    pageData?.name != ""
                      ? event.target.value.replace(/[<>:"/\\|?*]/g, '')
                      : event.target.value.trim(),
                      "name",
                      pageData,
                      setPageData
                  )}
                }
                value={pageData?.name}
                bottom={-20}
                msgColor='red'
                onButtonClick={pageIndex !== 8 ? finishedHandler : null}
               />
              </Box>
          </Box>
          <Box className='flex' sx={{justifyContent:'flex-end', mb:5, mr:5}}>
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer', opacity: pageIndex === 1 && 0.5}} onClick={pageIndex !== 1 ? prevPageHandler : null}>/\</Box> */}
            {/* <Box className='flex a-center j-center' sx={{height:'40px', width:'40px', backgroundColor:'green', cursor:'pointer',  opacity: pageIndex === 8 && 0.5}} onClick={pageIndex !== 8 ? finishedHandler : null}>\/</Box> */}
            <UpIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)}  sx={{opacity: pageIndex === 1 && 0.5, fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 1 ? prevPageHandler : null}/>
            <DownIcon  color={theme.palette.primary.main === ('#00d744' || '#00D744') ? '#00d744' :  getContrastRatio('#08231B', theme.palette.primary.main) > 4.5 ? `${theme.palette.primary.main}` : generateLighterContrastColor(theme.palette.primary.main)}  sx={{ cursor:'pointer', fontSize:{xs:'45px', sm:'55px', lg:'65px',xl:'82px'}}} onClick={pageIndex !== 8 ? finishedHandler : null}/>
          </Box>
        </Box>
    </Backdrop>
    </Slide>
    </>
  )
}

export default ProjectNamePage