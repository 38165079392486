import React from 'react'
// import CustomDrawer from './components/CustomDrawer'
import '../../App.css'
import { Box, TextField, Typography } from '@mui/material'
import { makeStyles } from "@mui/styles";
// import CustomInput from './components/CustomInput';
import { useState } from 'react';
import useEventRequest from '../../hooks/useEventRequest';
// import useEventList from '../../hooks/useEventList';
import { useDispatch, useSelector } from 'react-redux';
// import CreateEvent from './components/CreateEvent';
import "@fontsource/rubik";
import useEventList from '../dashboard/hooks/useEventList';
import CreateEvent from '../eventcreation/components/CreateEvent';
import CustomDrawer from '../eventcreation/components/CustomDrawer';
import { resetTeamPageData, resetTeamState, updateTeamState } from '../../redux/teamSlice';
import CreateTeam from './components/CreateTeam';
// import CustomInput from '../../components/CustomIn
// import CreateEvent from '../auth/dashboard/events/EventCreationV2';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight:'100vh',
    // maxWidth:'100vw',
    backgroundImage: 'linear-gradient(140deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
    display:'flex',
    width:'100vw',
    minWidth:'860px'
},

  scroll: {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }

  }
}));


const TeamCreationDrawer = (props) => {

    const classes = useStyles()
    const {openDrawer, setOpenDrawer} = props
    const initialData = {
      name : "",
      start_date: "",
      end_date: "",
      genre_id: "",
      event_tones: [],
      video_orientation: "landscape", // landscape | portrait
      phone_count_id: "",
      quality_options: [],
      description: ""

  }
const [pageData, setPageData] = useState(initialData);
const [snackbarProps, setSnackbarProps] = useState({});
const [refetchEvents] = useEventList();

//const [enableEvents, setEnableEvents] = useState(false);

const dispatch = useDispatch()

const [pageIndex, setPageIndex] = useState(0);
const teamsReducer = useSelector((state) => state.root.teamsReducer);

const handleDrawerToggle = () => {
  //setOpenDrawer((last) => !last);
  // dispatch(resetTeamState())
  dispatch(resetTeamPageData())
  dispatch(updateTeamState({open_create_team_form: false}))
};


  return (
    <CustomDrawer openDrawer={teamsReducer.open_create_team_form} handleDrawerToggle={handleDrawerToggle}>

          <Box textAlign='left'  sx={{marginBottom:{xs:'10px',sm:'10px',md:'15px',lg:'20px',xl:'40px'}}}>
            <Typography sx={{fontFamily: 'Rubik', fontSize:{xs:'18px',sm:'18px',md:'16px',lg:'20px',xl:'30px'}, fontWeight:600, color:'#000'}} >{"Create a Team"}</Typography>
          </Box>
         <CreateTeam/>
        
    </CustomDrawer>
  )
}

export default TeamCreationDrawer