import { Box, Typography } from "@mui/material"
import luciLogo from "../../../assets/luciLogo2.png"
import poweredBy from "../../../assets/poweredBy.svg"
import { makeStyles } from "@mui/styles";
import {    timeStampToDayString, timeStampToFormatTimeZone,  } from "../../../utils/commonServices";
import "@fontsource/rubik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import usePermissions from "../../../hooks/usePermissions";
import { PERMISSION } from "../../../constants/constants";
import { updateCreationState } from "../../../redux/eventCreationSlice";
import CustomButton from "../../../components/CustomButton";
import useCustomTheme from "../../../theme/useCustomTheme";
import { updateProjectState } from "../../../redux/projectSlice";
import CustomCard from "../../events/components/CustomCard";

const useStyles = makeStyles((theme) => ({
 fontWeightNormal:{
  fontWeight: 'normal'
 }, 
fontWeight:{
  fontWeight:600
},
rubik:{
  fontFamily:'Rubik'
}

}));

const GuestEventDetailCard = (props) => {


  const themeReducer = useSelector((state) => state.root.themeReducer);
  const guestReducer = useSelector(state=> state.guestReducer)
  const dispatch = useDispatch()
  const [hasPermission] = usePermissions()
  const [theme ] = useCustomTheme()

  const {event} = props
  const brandLogo = themeReducer?.brand_logo

  const classes = useStyles();




  return (
    <CustomCard className='rubik' style={{ height:'100%', padding:0 , '& .MuiCardContent-root:last-child':{paddingBottom:0}}} >
      <Box className="rubik" sx={{height:'100%', display:'flex', flexDirection:'column', paddingBottom:{xs:'24px',sm:'24px',md:'24px',lg:'30px',xl:'40px'} }}>
        <Box className="rubik flex j-center a-center" sx={{ width: '100%', marginTop:{xs:'25px',sm:'20px',md:'7%', lg: '8%',xl:'8%'}, marginBottom:{xs:'25px',sm:'20px',md:'3%',lg:'4%',xl:'4%'},}} >
          <Box  width='35%'>
           <img src={!!brandLogo ? brandLogo : luciLogo} style={{maxWidth:'70px', maxHeight:'35px'}} />
           {
                !!brandLogo && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
            }
            </Box>
        </Box>

        <Box className="rubik" sx={{display:'felx', flexDirection:'column', fontSize: {xs:'10px',md:'12px',lg:'14px',xl:'16px'},  marginRight:{md:'10px',lg:'0.52vw',xl:'0.78vw'}}}>
          <Box mb={{xs:0.5,md:0.8,   xl:1.3}} sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography sx={{fontWeight:'normal',  fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Lucivideo Name</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px', wordWrap: 'break-word'}, textTransform:'capitalize', fontFamily:'Rubik'}}>{guestReducer?.event_details?.name}</Typography>
          </Box>
          <Box mb={{xs:0.5, md:0.8,  xl:1.3}} sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Status</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{guestReducer?.event_details?.status_type}</Typography>
          </Box>
          <Box mb={{xs:0.5, md:0.8,  xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Event Type</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{guestReducer?.event_details?.event_type}</Typography>
          </Box>
          <Box mb={{xs:0.5,md:0.8,   xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Creation Date</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{timeStampToDayString(guestReducer?.event_details?.start_date)}, {timeStampToFormatTimeZone(guestReducer?.event_details?.start_date)}</Typography>
          </Box>
          {/* <Box mb={2}>
            <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>End Date</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{timeStampToDayString(commonReducer?.event_details?.end_date)}, { timeStampToFormatTimeZone(commonReducer?.event_details?.end_date)}</Typography>
          </Box> */}

          <Box mb={{xs:0.5,md:0.8, xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Editing Start Date</Typography>
            <Typography sx={{fontWeight:theme.typography.fontWeightBold , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>{timeStampToDayString(guestReducer?.event_details?.editor_start_date)}, {timeStampToFormatTimeZone(guestReducer?.event_details?.editor_start_date)}</Typography>
          </Box>

         { (hasPermission(PERMISSION.PROJECT.INFO.EDIT)  || guestReducer?.event_details?.description ) && <Box mb={{xs:0.5, md:0.8, xl:1.3}}  sx={{paddingLeft:{xs:'1.38vw',sm:'1.6vw',md:'1.8vw' ,lg:'2.3vw', xl:'2.9vw'}, }}>
            <Box className="flex a-center" gap={{xs:0.5, xl:1}}>
              <Typography  sx={{color:'#878887' , fontSize: {xs:'9px',md:'9px',lg:'10.67px',xl:'16px'}, fontFamily:'Rubik'}}>Notes</Typography>
                  {/* {commonReducer?.event_details?.description && <CustomTooltip title={commonReducer?.event_details?.description}  PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [-35, -4],
                                },
                              },
                            ],
                          }} >
                      <InfoOutlinedIcon sx={{fontSize: {xs:'9px !important',md:'9px !important',lg:'10.67px !important', xl:'16px !important'}, color:'#08231B'}}/>
                  </CustomTooltip>} */}
            </Box>
          </Box>}
        </Box>
      </Box>
    </CustomCard>
  )
}

export default GuestEventDetailCard