import React from 'react'
import CustomCard from '../../../components/CustomCard'
import { Avatar, Box, IconButton, ListItemText, Menu, MenuItem, Switch, Typography, getContrastRatio } from '@mui/material'
import { getInitials } from '../../../utils/commonServices'
import CustomButton from '../../../components/CustomButton'
import luciLogo from "../../../assets/luciLogo2.png";
import poweredBy from "../../../assets/poweredBy.svg";
import { useDispatch, useSelector } from 'react-redux'
import { updateTeamState } from '../../../redux/teamSlice'
import { exampleTeamMembers } from '../../../constants/staticData'
import { useEffect } from 'react'
import { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from "@mui/styles";
import {  styled } from '@mui/material/styles';
import CustomSwitch from '../../../components/CustomSwitch'
import { updateTeamScope } from '../../teams/actions'
import usePermissions from '../../../hooks/usePermissions'
import { PERMISSION } from '../../../constants/constants'
import CustomTooltip from '../../../components/CustomTooltip'
import { useNavigate } from 'react-router-dom'
// import { customTheme as theme } from '../../../theme/customTheme'
import useCustomTheme from '../../../theme/useCustomTheme'

const useStyles = makeStyles((theme) => ({
    container: {
      minHeight:'100vh',
      // maxWidth:'100vw',
      backgroundImage: 'linear-gradient(140deg, #F7F7F730,  #E6E6E650, #F7F7F730 , #E6E6E650, #E6E6E670,#00CEFF20 , #00CEFF50 80%,  #00D74460 , #00D74460 )',
      display:'flex',
      width:'100vw',
      minWidth:'860px'
  },
  
    scroll: {
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
  }));

const TeamLogoCard = () => {

    const classes = useStyles()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const [anchorEl, setAnchorEl] = useState(null);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer);
    const themeReducer = useSelector((state) => state.root.themeReducer);
    // const userPermissions = useSelector((state) => state.permissionsReducer.permissions)
    // const canAddOrganizationMembers = hasPermission(userPermissions, 'addMembers');
    // const brandLogo = window.localStorage.getItem('brand_logo_url')
    const brandLogo = themeReducer?.brand_logo
    const [testmembers, setTestmembers] = useState(teamsReducer?.selected_team_members?.users)
    const [teamName, setTeamName] = useState(teamsReducer.selected_team?.name)
    const [checked, setChecked] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [hasPermission] = usePermissions()
    const [theme ] = useCustomTheme()
   

    useEffect(()=>{
        setTestmembers(teamsReducer?.selected_team_members?.users)
      },[teamsReducer?.selected_team, teamsReducer?.selected_team_members?.users])

      useEffect(()=>{
        setTeamName(teamsReducer.selected_team?.name)
        setChecked(!teamsReducer.selected_team?.public)
      },[teamsReducer.selected_team?.name, teamsReducer.selected_team?.public])

    //   console.log({testmembers})
      const open = Boolean(anchorEl);

      const handleClick = (event) => {
          event.stopPropagation()
          setAnchorEl(event.currentTarget);
      };

    const colors =[theme.palette.primary.main, '#000000', '#6C00FF']

    const handleClose = (e) => {
        // e.stopPropagation();
        setAnchorEl(null);
      
    };

    useEffect(()=>{
        dispatch(updateTeamState({selected_team: teamsReducer?.all_teams?.find(team=> team.id == teamsReducer.selected_team_members?.id)}))
    },[teamsReducer?.all_teams])



    const deleteTeamHandler = (e, team) => {
        e.stopPropagation()
        dispatch(updateTeamState({delete_team_popup :true , selected_team: team}))
        console.log("Delete Team:", team);
        handleClose();
      };
    
   
      const renameTeamHandler = (e,team) => {
        e.stopPropagation()
        dispatch(updateTeamState({rename_team_popup:true,  selected_team: team}))
        console.log("Rename Team:", team);
        handleClose();
      };

      
      const privacyChangeHandler =(e, team) => {
  
        ///api to chenge team scope/////
        e.stopPropagation()
        const payload = {
            team_id: teamsReducer.selected_team?.id,
            // public: !team?.public
            public: !teamsReducer.selected_team?.public
          }
    
          console.log({team})
          console.log({payload})
          updateTeamScope(payload, dispatch, handleClose)
        if(e.target.checked == true){
          setChecked(true)
        }else{
          setChecked(false)
        }
      }

      const addMembersClickHandler =()=>{
        dispatch(updateTeamState({invite_team_members_popup : true}))
    }

    const viewMembersClickHandler =()=>{
        // viewOrganisationMember({id:2}, dispatch, navigate)
        dispatch(updateTeamState({view_members_popup : true}))
    }

  return (
    <>
                <CustomCard               
                    margin='0px 0px 20px 0px'
                    padding='15% 27% 7.3% 21.3%'
                    sx={{ flex: 3 }}      
                >
                    <Box height='100%'  className='flex col j-start a-center' >

                    <Box textAlign='center' sx={{ width: '60%' }} >
                            <img src={brandLogo !== null ? brandLogo : luciLogo}  width='100%' style={{cursor:'pointer'}} onClick={()=>navigate('/dashboard', {replace: true})} />
                            {
                               brandLogo !== null && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
                            }
                        </Box>
                        <Box className='flex j-center a-center' textAlign='center' sx={{mt: {xs: 1, md: 2, lg: 2, xl: 4}, width:'100%'}}>
                          <CustomTooltip
                          title={teamName} 
                          placement= "bottom"
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -8],
                                },
                              },
                            ],
                          }} >
                            <Typography sx={{fontFamily: 'Rubik', fontWeight: 300, width:'100%', fontSize: {xs:'16px',md:'18px',lg:'23px',xl:'32px'}, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', textTransform:'capitalize'}} > {teamName}</Typography>
                          </CustomTooltip>
                            {hasPermission(PERMISSION.ORGANISATION.TEAM.DELETE) && <IconButton edge="end"  onClick={handleClick}>
                                <ExpandMoreIcon  style={{ fontSize: '14px' }} />
                            </IconButton>}
                            {/* <Typography sx={{fontFamily: 'Rubik', color: '#000000', fontWeight: 600, fontSize: {xs:'10px',md:'12px',lg:'13.5px',xl:'16px'}, textTransform:'capitalize'}} ></Typography> */}
                        </Box>
                        <Box className='flex' gap={1} p={1.5}>
                            {testmembers?.slice(0, 3).map((item, index) => (
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: colors[index],
                                    color:getContrastRatio( colors[index], '#ffffff') > 2.5 ? '#ffffff' : '#000000',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    fontWeight:600
                                    }}
                                >
                                    { item === null ? 'NA': getInitials(item?.name)}
                                </Avatar>
      
                            ))}
                            {testmembers?.length > 3 && (
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    border:'1px solid black',
                                    fontWeight:600
                                    }}
                                >
                                    +{testmembers?.length - 3}
                                </Avatar>
                            )}
                        </Box>
                        <Box>
                            <CustomButton 
                              btnText={hasPermission(PERMISSION.TEAM.MEMBER.ADD)  ? 'Add Members' : 'View Members'}
                            onClick={hasPermission(PERMISSION.TEAM.MEMBER.ADD)  ? addMembersClickHandler : viewMembersClickHandler}  

                            // btnText='Add Team Members'
                         color='secondary'
                         variant='contained'
                            // onClick={()=>  dispatch(updateTeamState({invite_team_members_popup : true}))}    
                            type='button'
                            style={{
                                borderRadius: '5px',
                                border: "none",          
                                textTransform:'none',
                                // backgroundColor:theme.palette.secondary.main
                                }}
                            sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"36px"}, width:{xs:'150px',sm:'150px',md:'150px',lg:'163px',xl:'255px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'13px'}, marginTop:{xl:1}}}
                            />
                        </Box>
                    </Box>
                </CustomCard>

            <Menu
            id="role-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
               
                vertical: 0,
                horizontal: 100
            }}
            PaperProps={{sx:{
                width:'180px'
            }}}
           
          >
             <MenuItem disableRipple  gap={2} sx={{display:'flex', justifyContent:'space-between'}}>
                <ListItemText >
                    <Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, } }}>Private</Typography>      
                </ListItemText>
                <div style={{justifySelf:'flex-end'}}>
                    <CustomSwitch
                        checked={checked}
                        onChange={(e)=> privacyChangeHandler(e)}
                    />
                </div>
            </MenuItem>
            <MenuItem disableRipple  onClick={(e) => renameTeamHandler(e, teamsReducer.selected_team)}>
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, } }}>Rename team</Typography></ListItemText>
            </MenuItem>
            <MenuItem disableRipple onClick={(e) => deleteTeamHandler(e, teamsReducer.selected_team)}>
                <ListItemText><Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '8px', md: '10px', lg: '11px', xl: '13px', fontWeight: 600, color: 'red' } }}>Delete team</Typography></ListItemText>
            </MenuItem>
     
        </Menu> 
    </>
  )
}

export default TeamLogoCard