
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, CircularProgress, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StarIcon from '@mui/icons-material/Star';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { downloadS3File, fileFormat, removeExtension, sizeConverter, timeStampToNumberedDateString } from '../../../utils/commonServices';
import { makeStyles } from "@mui/styles";
import CustomTooltip from '../../../components/CustomTooltip';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { setAllMediaFilesArray } from '../../../redux/mediaFilesSlice';
import CustomScrollToBottom from '../../../components/CustomScrollToBottom';
import { BRANDBOX_OPTIONS } from '../../../constants/staticData';
import DeleteIcon from '@mui/icons-material/Delete';
import { setAssetState } from '../../../redux/assetSlice';
import { deleteAsset } from '../actions';

const useStyles = makeStyles((theme) => ({
    checkboxRoot: {
        pointerEvents: 'none',
      },
    container: {
        width:'100%',
        borderCollapse: 'collapse',
       // paddingRight:'4%'
       scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
                 display : 'none'
               }
    },
    tableRowHead:{
        textAlign:'left',
        verticalAlign:'top',
        color:'#08231B',
        fontSize:'15px',
        fontFamily: "Rubik",
        fontWeight:500,
        letterSpacing: '0.75px',
        zIndex: 15
    },
    tableRowData:{
        borderBottom:'2px solid #EBEBEB',
        // height:{xs:'50px',md:'55px',lg:'60px', xl:'68px'},
        // height:'62px',
        backgroundColor:'transparent',
        fontFamily: "Rubik",
       // textAlign:'left',
        width:'100%',
        fontSize:'15px',
        // font: 'normal normal 600 15px/18px ',
        fontWeight:600,
        color:'#08231B',
        letterSpacing: '0.75px',
        zIndex: 15,
        '&:last-child':{
            borderBottom:'none'
         }
    },
 
  }));

const BrandboxOptions = (props) => {

    const classes = useStyles();
    const {files, popup, expanded, setExpanded}= props
    const assetReducer = useSelector((state) => state.assetReducer);
  //  const [expanded, setExpanded] = useState('');
    const dispatch = useDispatch()
    const [fileId, setFileId] = useState('')


    const handleAccordianChange = (project) => (event, isExpanded) => {
     
      setExpanded(isExpanded ? project?.label : false);
      dispatch(setAssetState({selected_asset_files: project?.files}))
    };

    const fileownloadHandler =  (file)=>{
      setFileId(file?.id)
      if(file?.url != null && file?.url != "") {
        downloadS3File(file?.download_url)
      }
    }
      const deleteFileHandler =  (id)=>{
      // console.log(id)
      setFileId(id)
      deleteAsset({id: id},dispatch , resetExpandedState)

      }

      const resetExpandedState =()=>{
        // setExpanded('')
      }

  return (
   
           <Box mt={3} className='flex col' sx={{minWidth:'600px'}}>
                <Box sx={{overflowY:'scroll', height: popup == true ? '30vh':'60vh'}}>

        
           {  assetReducer.all_assets?.length === 0 ?  <Typography sx={{fontSize: 30, color: '#878887', fontWeight: 300, textAlign: 'center', mt:10}} >Click upload to add files. </Typography> : 
         
         assetReducer.all_assets?.map((project)=> ( 
                <>

                {expanded === project?.label && <CustomScrollToBottom /> }
                <Accordion id= 'assets'  key={project?.label} expanded={expanded === project?.label} style={{backgroundColor:'transparent', }} disableGutters={true} onChange={handleAccordianChange(project)}>
                  
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#08231B' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
//                   sx={{'& .MuiPaper-elevation': {
//                     borderBottom: '2px solid #08231B !important', 
//                     boxShadow: 'none !important'
// }}}
                >
                  <Typography  sx={{fontSize: {xs:'11px',md:'13px',lg:'14px',xl:'18px'}, fontFamily: 'Rubik' , color:'#08231B', textTransform:'capitalize', fontWeight:800}}>{project?.label}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                <Box sx={{ display:'flex', alignItems:'center'}} mb={{xs:2,xl:3.5}}>
                    <Box    style={{ width:'40%'}}><Typography  sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik' , fontWeight: 800, color:'#08231B'}}>File Name</Typography></Box>

                    <Box  style={{ marginLeft: '1%', width:'10%'}}><Typography textAlign='left' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'},  fontFamily: 'Rubik' , fontWeight: 800, color:'#08231B'}}>Type</Typography></Box>

                    <Box  style={{  marginLeft: '1%', width: '10%'}}><Typography textAlign='left' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik' , fontWeight: 800, color:'#08231B' }}>Size</Typography></Box>

                    <Box  style={{  marginLeft: '1%', width: '10%'}}><Typography textAlign='left' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', fontWeight: 800, color:'#08231B'}}>Upload Date</Typography></Box>


                    <Box   style={{ marginLeft: '1%',  width: '20%'}}><Typography textAlign='left' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', fontWeight: 800, color:'#08231B'}}>Uploaded By</Typography></Box>
                    <Box   style={{ marginLeft: '1%',  width: '10%'}}><Typography textAlign='center' sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', fontWeight: 800, color:'#08231B'}}></Typography></Box>
              

                    <Box flex={1}   ><Typography  sx={{fontSize: {xs:'10px',md:'13px',lg:'14.67px',xl:'21px'}, fontFamily: 'Rubik', color:'#08231B'}}></Typography></Box>
                </Box>

                     {project?.files?.length  == 0 ? (<Typography textAlign={'center'} mt={'5%'}>{'No files to show'}</Typography>) : project?.files?.map((file, index) => (
                        <Box className={classes.tableRowData}  key={file?.id} sx={{display:'flex',  alignItems:'center', height: popup == true ?{md:'40px',lg:'45px', xl:'60px'}:{lg:'55px',xl:'65px'}}}>
                         
                
                    <CustomTooltip title = {file?.filename} >
                            <Box   style={{ width: '38%'}}><Typography  sx={{fontSize: {xs:'10px',md:'11px',lg:'13px',xl:'19px', whiteSpace: "nowrap", overflowX: "clip", width:'96%'}, fontWeight:400, fontFamily: 'Rubik'}}>{removeExtension(file?.filename)}</Typography></Box>
                    </CustomTooltip> 

                            <Box    style={{  marginLeft: '1%', width:'9.5%'}}><Typography textAlign='left' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:400, fontFamily: 'Rubik' }}>{file?.type}</Typography></Box>

                            <Box    style={{ marginLeft: '1%', width:'9.5%'}}><Typography textAlign='left' sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, fontWeight:400, fontFamily: 'Rubik'}}>{sizeConverter(parseInt(file?.size))}</Typography></Box>

                            <Box    style={{ marginLeft: '1%', width:'9.5%'}}><Typography textAlign='left' sx={{fontSize: {xs:'10px',md:'11px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik'}}>{timeStampToNumberedDateString(file?.created_at)}</Typography></Box>


                            <Box   style={{ marginLeft: '1%', width:'19%'}}><Typography textAlign='left' sx={{fontSize: {xs:'10px',md:'11px',lg:'13px',xl:'19px'}, fontWeight:400, fontFamily: 'Rubik'}}>{file?.created_by}</Typography></Box>

                           

                            <Box flex={1}  sx={{ display:'flex', justifyContent:'end'}}>
                            <Box sx={{fontSize: {xs:'8px',md:'9px',lg:'11px',xl:'16px'}, display:'flex', alignItems:'center',justifyContent:'end' }} gap={2}>
                
                            <>     
                           { assetReducer?.delete_asset_loading === true && fileId == file?.id ?   <CircularProgress    
                                sx={{
                                  height:'3px', width:'3px',
                                  margin:{xs:0.75,md:0.87,lg:1.1, xl:1.4},
                                  color:'#666'
                                  }}
                                size={14}
                                loading={ assetReducer?.delete_asset_loading === true && fileId == file?.id}
                                disabled 
                              >    
                              </CircularProgress> : <IconButton  
                            disabled = {assetReducer?.delete_asset_loading === true}
                            onClick={()=>deleteFileHandler(file?.id)}
                             sx={{fontSize: {xs:'10px',md:'12px',lg:'15px',xl:'20px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'10px',md:'12px',lg:'15px',xl:'20px'}
                           }}}>
                            <DeleteIcon  sx={{fontSize: {xs:'10px',md:'12px',lg:'15px',xl:'20px'}, '& .MuiSvgIcon-root':{
                            fontSize:{xs:'10px',md:'12px',lg:'15px',xl:'20px'},
                           }}}/>
                         </IconButton>    }   

                            <IconButton 
                             disabled = {assetReducer?.delete_asset_loading === true} 
                              onClick={()=>fileownloadHandler(file)} 
                              sx={{fontSize: {xs:'11px',md:'13px',lg:'16px',xl:'21px'}, 
                                  '& .MuiSvgIcon-root':{fontSize:{xs:'11px',md:'13px',lg:'16px',xl:'21px'},}}} 
                            >
                              <FileDownloadOutlinedIcon  sx={{fontSize: {xs:'11px',md:'13px',lg:'16px',xl:'21px'},  '& .MuiSvgIcon-root':{fontSize:{xs:'11px',md:'13px',lg:'16px',xl:'21px'},}}}/>
                            </IconButton>
                            </>
                        </Box>
                    </Box>
               </Box>
               ))} 

               </AccordionDetails>
          </Accordion>
          </>
          ))}
            </Box>
            </Box>
  )
}

export default BrandboxOptions