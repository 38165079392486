import React from 'react'
import CustomCard from '../../../components/CustomCard'
import { Avatar, Box, Typography, getContrastRatio } from '@mui/material'
import { getInitials, hasPermission } from '../../../utils/commonServices'
import CustomButton from '../../../components/CustomButton'
import luciLogo from "../../../assets/luciLogo2.png";
import poweredBy from "../../../assets/poweredBy.svg";
import { useDispatch, useSelector } from 'react-redux'
import { updateTeamState } from '../../../redux/teamSlice'
import { exampleTeamMembers } from '../../../constants/staticData'
import { useEffect } from 'react'
import { useState } from 'react'
import { addOrganisationMember, viewOrganisationMember } from '../actions'
import { useNavigate } from 'react-router-dom'
import usePermissions from '../../../hooks/usePermissions'
import { PERMISSION } from '../../../constants/constants'
// import { customTheme as theme } from '../../../theme/customTheme'
import useCustomTheme from '../../../theme/useCustomTheme'

const MainLogoCard = () => {

    // const primaryColor = localStorage.getItem('primary')
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const teamsReducer = useSelector((state) => state.root.teamsReducer);
    const permissionsReducer = useSelector((state) => state.permissionsReducer);
    const [testmembers, setTestmembers] = useState(teamsReducer.organization_members)
    const themeReducer = useSelector((state) => state.root.themeReducer);
    // const userPermissions = useSelector((state) => state.permissionsReducer.permissions)
    // const canAddOrganizationMembers = hasPermission(userPermissions, 'addMembers');
    // const brandLogo = window.localStorage.getItem('brand_logo_url')
    const brandLogo = themeReducer?.brand_logo
    const [hasPermission] = usePermissions()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [theme] = useCustomTheme()

    useEffect(()=>{
        setTestmembers(teamsReducer.organization_members)
      },[teamsReducer.organization_members])

    const colors =[theme.palette.primary.main, '#000000', '#6C00FF']

    const addMembersClickHandler =()=>{
        dispatch(updateTeamState({invite_team_members_popup : true}))
    }

    const viewMembersClickHandler =()=>{
        // viewOrganisationMember({id:2}, dispatch, navigate)
        dispatch(updateTeamState({view_members_popup : true}))
    }



  return (
    <>
                <CustomCard               
                    margin='0px 0px 20px 0px'
                    padding='15% 27% 7.3% 21.3%'
                    sx={{ flex: 3 , backgroundColor:'#ffffff !important'}}      
                >
                    <Box height='100%' className='flex col j-start a-center' >

                    <Box textAlign='center' sx={{  width:'60%'  }} >
                          
                            <img src={brandLogo !== null ? brandLogo : luciLogo}   style={{maxWidth:'60px', maxHeight:'30px'}}/>
                            {
                               brandLogo !== null && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
                            }
                            
                        </Box>
                        <Box textAlign='center' sx={{mt: {xs: 1, md: 2, lg: 2, xl: 4}}}>

                            <Typography sx={{fontFamily: 'Rubik', fontWeight: 300, fontSize: {xs:'16px',md:'18px',lg:'23px',xl:'32px'}}} > Hello</Typography>
                            <Typography sx={{fontFamily: 'Rubik', color: '#000000', fontWeight: 600, fontSize: {xs:'10px',md:'12px',lg:'13.5px',xl:'19px'}, textTransform:'capitalize'}} >{commonReducer.user_name}</Typography>
                        </Box>
                        <Box className='flex' gap={1} p={1.5}>
                            {testmembers?.slice(0, 3).map((item, index) => (
                                <Avatar
                                    key={index}
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: colors[index],
                                    color: getContrastRatio(colors[index], '#ffffff') > 2.5 ? '#ffffff' : '#000000',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    fontWeight:600
                                    }}
                                >
                                    {getInitials(item.name)}
                                </Avatar>
      
                            ))}
                            {testmembers?.length > 3 && (
                                <Avatar
                                    sx={{
                                    borderRadius: '5px',
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    width: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    height: { xs: '18px', sm: '20px', md: '22px', lg: '23.5px', xl: '35px' },
                                    paddingRight: 0,
                                    marginRight: 0,
                                    fontSize: { xs: '7px', md: '8px', lg: '8px', xl: '12px' },
                                    textTransform: 'uppercase',
                                    fontFamily: 'Rubik',
                                    border:'1px solid black',
                                    fontWeight:600
                                    }}
                                >
                                    +{testmembers?.length - 3}
                                </Avatar>
                            )}
                        </Box>
                        <Box>
                            <CustomButton 
                            btnText={hasPermission(PERMISSION.ORGANISATION.MEMBER.ADD) ? 'Add Members': 'View Members'}
                            onClick={hasPermission(PERMISSION.ORGANISATION.MEMBER.ADD) ? addMembersClickHandler: viewMembersClickHandler}  
                            type='button'
                            color='secondary'
                            variant='contained'
                            style={{
                                borderRadius: '5px',
                                border: "none",          
                                textTransform:'none',
                                // backgroundColor:theme.palette.secondary.main
                                }}
                            sx={{fontSize:{xs:'8px',sm:'9px',md:'10px',lg:'11px',xl:'16px'}, fontWeight:600, height:{xs:'18px',sm:'18px',md:'20px',lg:'22px',xl:"36px"}, width:{xs:'150px',sm:'150px',md:'150px',lg:'163px',xl:'255px'}, fontFamily:'Rubik', marginBottom:{xs:'8px',md:'10px',lg:'11px',xl:'12px'}, marginTop:{xl:1}}}
                            />
                        </Box>
                    </Box>
                </CustomCard>
    </>
  )
}

export default MainLogoCard