import { AppBar, Box, Toolbar } from '@mui/material'
import React from 'react'
import CustomUserCard from '../../events/components/CustomUserCard'
import luciLogo from '../../../assets/luciLogo2.png'
import poweredBy from '../../../assets/poweredBy.svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const LogoHeader = () => {

    const navigate = useNavigate()
    const commonReducer = useSelector((state) => state.root.commonReducer);
    const themeReducer = useSelector((state) => state.root.themeReducer);
    // const brandLogo = window.localStorage.getItem('brand_logo_url')
    const brandLogo = themeReducer?.brand_logo

  return (
    // <AppBar  sx={{backgroundColor:'transparent', }} elevation={0} >
    //     <Toolbar  sx={{  display:'flex', justifyContent:{xs:'center', md:'start'}, alignItems:'center'}}>
    //     <Box sx={{ width:"10%"}}>
    //             <Box >
    //                 <img src={brandLogo !== null ? brandLogo : luciLogo} width= '100%' />
    //                 {
    //                     brandLogo !== null && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
    //                 }
    //             </Box>
    //     </Box>
    //     </Toolbar>
    // </AppBar>
    <Box  sx={{backgroundColor:'transparent',maxWidth:'100%',  justifyContent:{xs:'center', md:'start'}, padding: '1.5% 3.4% 2.1% 3.4%' , pt:{xs:4, sm:3, md:2}}} elevation={0}  className='flex a-center'>
    {/* <Toolbar  sx={{  display:'flex', justifyContent:{xs:'center', md:'start'}, alignItems:'center'}}> */}
    <Box sx={{ width:{xs:"15%", md:'9%',lg:'9%', xl:'9%'},}}>
            <Box >
                <img src={!!brandLogo ? brandLogo : luciLogo} width= '100%'  style={{maxWidth:'60px', maxHeight:'30px'}} />
                {
                    !!brandLogo && <Box textAlign='right'><img src={poweredBy}  width='50%'/></Box> 
                }
            </Box>
    </Box>
    {/* </Toolbar> */}
</Box>
  )
}

export default LogoHeader