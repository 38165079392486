import React, { useState } from "react";
import CustomDateTimePicker from "./CustomDateTimePicker";
import { Box, Typography } from "@mui/material";
import CheckIcon from "../icons/CheckIcon";

const DateTimePicker = (props) => {

  // const [dateTime, setDateTime] = useState(new Date())
  const {placeholder, onDateChange , onFocus, onBlur, required, errorFlag, errorMsg, disabled, defaultValue, right, extendEditingDate, buttonClickHandler, buttonDisabled, dateTime, setDateTime , medium} = props
  ///console.log({errorFlag, errorMsg, required})

  const changeHandler = (value) => {
   //  console.log('change hangler',value)
    onDateChange(value.getTime()/1000)
    setDateTime(value)
  }
// console.log(medium)

  return (<>
    <div className="main" {...props} style={{width: extendEditingDate == true && '220px', marginLeft:extendEditingDate == true && '25px', }}>
      <CustomDateTimePicker 
          right = {right}
          showTimer={true} 
          numIntervals={30} 
          hideLast={true}
          hideFuture={false}
          value={dateTime?.toLocaleString('en-US', {weekday: 'short', day: "2-digit", month: "2-digit", year: "numeric", hour: 'numeric', hour12: true, minute:'numeric'})}
          changeValue={changeHandler}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          required
          disabled={disabled}
          defaultValue={defaultValue}
          extendEditingDate={extendEditingDate}
          buttonClickHandler={buttonClickHandler}
          buttonDisabled={buttonDisabled}
          medium={medium}
          /> 
         
        {required && errorFlag && <Typography
            variant="body1"
            className="errorDom"
            component="span"
            sx={{ color: medium ? 'red' : "rgb(211, 47, 47)", fontSize: medium === true ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'6px',lg:'8px',xl:'13px'}, fontWeight: medium && 600 }}
          >
            <Box mt={0.5} >{errorMsg}</Box>
          </Typography>
        }

    </div>
  </>);
};
export default DateTimePicker